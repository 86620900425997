import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import qs from 'qs';

import {
  ANALYTICS_INTEGRATION_TYPES,
  DRILLDOWN_COLUMNS_KEYS,
  MEDIA_TYPES,
  MEDIA_TYPES_BY_API_KEY,
  MENTION_COLUMN_HEADERS,
  ONLY_MANUALLY_ADDED,
  ArticleListContext,
  ARTICLES_SORT_DEFAULT_ID,
  DESC_SORT_DIRECTION,
  ASC_SORT_DIRECTION,
  UTC_DATE_FORMAT_INTL,
  LICENSED_CONTENT_LABEL,
  DEV_FEATURES,
} from 'constants/constants';
import constantMessages from 'constants/constants.messages';

import MEDIA_TYPES_VALIDATIONS from 'pages/EarnedSearch/utils/mediaTypeValidations';
import { updateAnalyticIntegration } from 'pages/WidgetDrilldown/drilldown-reducer';
import columnConfig, {
  DESKTOP_READERSHIP_COLUMN,
  DESKTOP_READERSHIP_NON_MOBILE_UVM_COLUMN,
} from 'pages/WidgetDrilldown/DrilldownArticleList/columnConfig';
import { userHasDevFeatureFlag } from 'services/feature-service/feature-service';
import {
  getAnalyticsIntegrationsEnabled,
  getCurrentUserAccountId,
  getCurrentUserId,
  getCurrentUserImpersonating,
} from 'services/user-service/user-service';

import { DATE_RANGE_KEYS, isValidDateRange } from 'utils/date/date-util';
import { gtag, gTagEventName } from 'utils/gtag';
import { removeWhitespaces, containsOnlyNumbers } from 'utils/strings';

import {
  DISCOVERY_ALL_MENTIONS_DATE_RANGE_CONFIG,
  EARNED_SEARCHES_DATE_RANGE_CONFIG,
} from './constants';
import messages from './DrilldownArticleList/form.messages';

export const buildKeywordFilterText = (
  widgetData,
  dataPoint,
  existingFilterText,
) => {
  let keywordFilterText = null;

  if (widgetData && widgetData.groupBy === 'SUB_SEARCH') {
    keywordFilterText = dataPoint;
  } else if (widgetData && widgetData.groupBy === 'COUNTRY') {
    keywordFilterText = `country:"${dataPoint}"`;
    if (existingFilterText) {
      keywordFilterText = keywordFilterText.concat(
        ` AND ${existingFilterText}`,
      );
    }
  } else if (existingFilterText) {
    keywordFilterText = existingFilterText;
  }

  return keywordFilterText;
};

export const isArticleDuplicated = article =>
  !!article.duplicatePublications && !!article.duplicatePublications.length;

export const findChildArticle = (childArticles, childArticleId) =>
  childArticles.find(article => article.id === childArticleId);

export const isDuplicatedPublication = article =>
  !!article?.duplicateReason && !!article?.duplicateReason?.length;

export const ARTICLE_FILTERABLE_UVM_COLUMNS = [
  'MOBILE_READERSHIP',
  'TOTAL_READERSHIP',
];

const ARTICLE_COLUMNS_TO_OMIT = [...MENTION_COLUMN_HEADERS];

export const isNotFilterableUvmMetric = currentColumn => {
  currentColumn = removeWhitespaces(currentColumn);
  return !ARTICLE_FILTERABLE_UVM_COLUMNS.some(
    column => column.toLowerCase() === currentColumn.toLowerCase(),
  );
};

export const isColumnToOmit = column =>
  ARTICLE_COLUMNS_TO_OMIT.includes(column);

export const getColumnsToShow = ({
  defaultColumns,
  googleAnalyticsColumns,
  adobeAnalyticsColumns,
  analyticsIntegrationType,
  isEarnedSearches,
  isMobileUvmEnabled,
  hasAllMentionsNewDrilldown = false,
}) => {
  const filtered = defaultColumns.filter(column => {
    if (hasAllMentionsNewDrilldown && isColumnToOmit(column)) {
      return false;
    }
    if (!isMobileUvmEnabled) {
      if (isEarnedSearches) {
        return isNotFilterableUvmMetric(column);
      }
    }
    return true;
  });

  if (!analyticsIntegrationType) {
    return filtered;
  }
  const analyticsColumns =
    analyticsIntegrationType === ANALYTICS_INTEGRATION_TYPES.googleAnalytics.id
      ? googleAnalyticsColumns
      : adobeAnalyticsColumns;

  return filtered.concat(analyticsColumns);
};

export const getColumnHeaderLabel = (
  intl,
  columnName,
  isEarnedSearches,
  isUvmEnabled,
  hasAllMentionsNewDrilldown = false,
) => {
  let labelMessage = columnConfig[columnName].label;

  if (columnName === DESKTOP_READERSHIP_COLUMN) {
    if (!isUvmEnabled && isEarnedSearches) {
      labelMessage =
        columnConfig[DESKTOP_READERSHIP_NON_MOBILE_UVM_COLUMN].label;
    } else {
      labelMessage = columnConfig[DESKTOP_READERSHIP_COLUMN].label;
    }
  } else if (
    columnName === DRILLDOWN_COLUMNS_KEYS.publicationDate &&
    hasAllMentionsNewDrilldown
  ) {
    labelMessage = messages.publicationDateDefaultLabel;
  }

  return intl.formatMessage(labelMessage);
};

export const getCustomDateRangeLabels = (startDate, endDate, intl) => {
  const startDateLabel = intl.formatDate(
    moment.utc(startDate),
    UTC_DATE_FORMAT_INTL,
  );
  const endDateLabel = intl.formatDate(
    moment.utc(endDate),
    UTC_DATE_FORMAT_INTL,
  );
  return {
    label: `${startDateLabel} - ${endDateLabel}`,
    shortLabel: '',
  };
};

export const selectItemBuilder = (id, label, selected, type = '') => ({
  id,
  label,
  selected,
  type,
});

export const onMediaTypeUpdateInclusionMode = (selected, notifyCallback) => {
  notifyCallback(
    selected.map(
      ({ id }) => MEDIA_TYPES_BY_API_KEY[id]?.mediaType || id.toUpperCase(),
    ),
  );
};

export const onMediaTypeUpdateExclusionMode = (selected, notifyCallback) => {
  let mediaTypes = MEDIA_TYPES_BY_API_KEY;

  const removeFromMediaTypes = entry => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [entry]: removing, ...result } = mediaTypes;
    return result;
  };

  selected.forEach(({ id: apiKey, label }) => {
    switch (apiKey) {
      case MEDIA_TYPES.radio.apiKey:
        mediaTypes = removeFromMediaTypes(MEDIA_TYPES.broadcast.apiKey);
        mediaTypes = removeFromMediaTypes(apiKey);
        break;

      case MEDIA_TYPES.tv.apiKey:
        mediaTypes = removeFromMediaTypes(MEDIA_TYPES.broadcast.apiKey);
        mediaTypes = removeFromMediaTypes(apiKey);
        break;

      case MEDIA_TYPES.manuallyAdded.apiKey:
        mediaTypes = removeFromMediaTypes(MEDIA_TYPES.manuallyAdded.apiKey);
        if (selected.length === 1) {
          mediaTypes = {
            ...mediaTypes,
            [ONLY_MANUALLY_ADDED]: { apiKey: ONLY_MANUALLY_ADDED },
          };
        }

        break;
      default:
        mediaTypes = removeFromMediaTypes(apiKey);
    }

    if (label === LICENSED_CONTENT_LABEL) {
      mediaTypes = removeFromMediaTypes(MEDIA_TYPES.blogLicensed.apiKey);
      mediaTypes = removeFromMediaTypes(MEDIA_TYPES.newsLicensed.apiKey);
      mediaTypes = removeFromMediaTypes(MEDIA_TYPES.newsedge.apiKey);
    }
  });

  notifyCallback(Object.values(mediaTypes).map(({ apiKey }) => apiKey));
};

export const getMediaTypeUpdateHandler = context => {
  if (!context) {
    return onMediaTypeUpdateExclusionMode;
  }

  if (
    userHasDevFeatureFlag(DEV_FEATURES.earnedSearchesV2Support) &&
    context === ArticleListContext.earnedSearches
  ) {
    return onMediaTypeUpdateInclusionMode;
  }
  return onMediaTypeUpdateExclusionMode;
};

const mediaTypeStatusValidator = (defaultSelectedMediaTypes = {}) => (
  mediaType = '',
) =>
  isEmpty(defaultSelectedMediaTypes) || !!defaultSelectedMediaTypes[mediaType];

export const generateMediaTypeItems = (
  intl,
  defaultSelectedMediaTypes = {},
) => {
  const buildMediaTypes = ({ apiKey, labelI18n }, selected = true) =>
    selectItemBuilder(
      apiKey,

      intl.formatMessage(constantMessages[labelI18n]),

      selected,
    );

  const isSelectedMediaType = mediaTypeStatusValidator(
    defaultSelectedMediaTypes,
  );

  const mediaTypesAvailable = [];

  mediaTypesAvailable.push(
    buildMediaTypes(
      MEDIA_TYPES.news,
      isSelectedMediaType(MEDIA_TYPES.news.apiKey),
    ),
  );

  mediaTypesAvailable.push(
    buildMediaTypes(
      MEDIA_TYPES.blog,
      isSelectedMediaType(MEDIA_TYPES.blog.apiKey),
    ),
  );
  if (MEDIA_TYPES_VALIDATIONS.canAccessBroadcastData()) {
    mediaTypesAvailable.push(
      buildMediaTypes(
        MEDIA_TYPES.radio,
        isSelectedMediaType(MEDIA_TYPES.radio.apiKey),
      ),
    );

    mediaTypesAvailable.push(
      buildMediaTypes(
        MEDIA_TYPES.tv,
        isSelectedMediaType(MEDIA_TYPES.tv.apiKey),
      ),
    );
  }

  if (MEDIA_TYPES_VALIDATIONS.canAccessPrintData()) {
    mediaTypesAvailable.push(
      buildMediaTypes(
        MEDIA_TYPES.printData,
        isSelectedMediaType(MEDIA_TYPES.printData.apiKey),
      ),
    );
  }

  if (MEDIA_TYPES_VALIDATIONS.canAccessNewsEdgeData()) {
    mediaTypesAvailable.push(
      buildMediaTypes(
        MEDIA_TYPES.newsedge,
        isSelectedMediaType(MEDIA_TYPES.newsedge.apiKey),
      ),
    );
  }

  if (MEDIA_TYPES_VALIDATIONS.canAccessFactivaData()) {
    mediaTypesAvailable.push(
      buildMediaTypes(
        MEDIA_TYPES.factiva,
        isSelectedMediaType(MEDIA_TYPES.factiva.apiKey),
      ),
    );
  }

  if (MEDIA_TYPES_VALIDATIONS.canAccessManuallyAddedData()) {
    const isSelected =
      isSelectedMediaType(MEDIA_TYPES.manuallyAdded.apiKey) ||
      isSelectedMediaType(ONLY_MANUALLY_ADDED);
    mediaTypesAvailable.push(
      buildMediaTypes(MEDIA_TYPES.manuallyAdded, isSelected),
    );
  }

  return mediaTypesAvailable.sort((a, b) => a.label.localeCompare(b.label));
};

export const getAnalyticsIntegrationsOptions = defaultValue => {
  const analyticsIntegrationList = getAnalyticsIntegrationsEnabled();

  return analyticsIntegrationList.map(
    ({
      analyticsId,
      analyticsIntegrationType,
      analyticsIntegrationAlias,
      analyticsIntegrationName,
    }) => {
      const prefix =
        analyticsIntegrationType ===
        ANALYTICS_INTEGRATION_TYPES.googleAnalytics.id
          ? ANALYTICS_INTEGRATION_TYPES.googleAnalytics.prefix
          : ANALYTICS_INTEGRATION_TYPES.adobeAnalytics.prefix;

      const analyticIntegrationLabel = `${prefix}: ${
        analyticsIntegrationAlias || analyticsIntegrationName
      }`;

      return selectItemBuilder(
        analyticsId,
        analyticIntegrationLabel,
        analyticsId === defaultValue,
        analyticsIntegrationType,
      );
    },
  );
};

export const onAnalyticIntegrationUpdate = (
  selection,
  dispatch,
  notifyCallback,
) => {
  const analyticIntegration = selection?.[0];

  notifyCallback(selection);

  dispatch(
    updateAnalyticIntegration({
      analyticsIntegrationType: analyticIntegration?.type,
    }),
  );
};

export const getDateRangeConfigByContext = context => {
  let dateRangeConfig = EARNED_SEARCHES_DATE_RANGE_CONFIG;

  if (context === ArticleListContext.discovery) {
    dateRangeConfig = DISCOVERY_ALL_MENTIONS_DATE_RANGE_CONFIG;
  }

  return dateRangeConfig;
};

export const buildSort = (sortColumn, sortAscending) => {
  const { sort } = columnConfig[sortColumn] || {
    sort: ARTICLES_SORT_DEFAULT_ID,
  };
  const direction = sortAscending ? ASC_SORT_DIRECTION : DESC_SORT_DIRECTION;
  return `${sort}-${direction}`;
};

const getDefaultAnalyticsIntegration = (
  analyticsIntegrationId,
  analyticsIntegrations,
) => {
  let analyticsIntegration = null;
  if (analyticsIntegrationId && containsOnlyNumbers(analyticsIntegrationId)) {
    analyticsIntegrationId = parseInt(analyticsIntegrationId, 10);
    analyticsIntegration = analyticsIntegrations?.find(
      analyticIntegration =>
        analyticIntegration.analyticsId === analyticsIntegrationId,
    );
  }
  analyticsIntegration =
    analyticsIntegration ||
    analyticsIntegrations?.find(
      analyticIntegration => analyticIntegration.isDefault,
    );
  return analyticsIntegration;
};

const sortDirectionRegex = new RegExp('(-desc|-asc)$');
const ascendingSortRegex = new RegExp('-asc$');

const getSort = (
  sort,
  allowedSortOptions,
  defaultSortColumn,
  defaultSortAscending,
) => {
  if (!sort) {
    return {
      sortColumn: defaultSortColumn,
      sortAscending: defaultSortAscending,
    };
  }
  const sortColumn = sort.replace(sortDirectionRegex, '');
  if (sortColumn.length === sort.length || !allowedSortOptions[sortColumn]) {
    return {
      sortColumn: defaultSortColumn,
      sortAscending: defaultSortAscending,
    };
  }

  const sortAscending = ascendingSortRegex.test(sort);
  return { sortColumn: allowedSortOptions[sortColumn], sortAscending };
};

const getPageNumber = pageNumber =>
  pageNumber && containsOnlyNumbers(pageNumber) ? parseInt(pageNumber, 10) : 1;

const getDateRange = (rangeType, startDate, endDate, defaultDateRangeType) => {
  const dateRangeType = DATE_RANGE_KEYS[rangeType]
    ? rangeType
    : defaultDateRangeType;
  let dateRangeData = { rangeType: dateRangeType };
  if (dateRangeType === DATE_RANGE_KEYS.CUSTOM) {
    const areValidDatesValues =
      containsOnlyNumbers(startDate) && containsOnlyNumbers(endDate);
    if (areValidDatesValues && isValidDateRange(startDate, endDate)) {
      dateRangeData = {
        ...dateRangeData,
        startDate: parseInt(startDate, 10),
        endDate: parseInt(endDate, 10),
      };
    } else {
      dateRangeData = { rangeType: defaultDateRangeType };
    }
  }
  return dateRangeData;
};

const getUnselectedMediatypesPurged = (unselectedMediatypes = []) =>
  unselectedMediatypes.filter(
    mediaTypeApiKey =>
      MEDIA_TYPES_BY_API_KEY[mediaTypeApiKey] ||
      mediaTypeApiKey === ONLY_MANUALLY_ADDED,
  );

export const getParamsFromBrowserUrl = ({
  location,
  analyticsIntegrations,
  defaultDateRangeType,
  allowedSortOptions,
  defaultSortColumn,
  defaultSortAscending = false,
}) => {
  const urlParams = qs.parse(location?.search?.split('?')[1]) || {};

  const {
    analyticsIntegrationId = '',
    sort = '',
    pageNum = '',
    rangeType = '',
    startDate = '',
    endDate = '',
    viewFilters = [],
  } = urlParams;
  const defaultAnalyticIntegration = getDefaultAnalyticsIntegration(
    analyticsIntegrationId,
    analyticsIntegrations,
  );
  const sortData = getSort(
    sort,
    allowedSortOptions,
    defaultSortColumn,
    defaultSortAscending,
  );
  const pageNumber = getPageNumber(pageNum);
  const dateRangeData = getDateRange(
    rangeType,
    startDate,
    endDate,
    defaultDateRangeType,
  );

  const unselectedMediatypes = getUnselectedMediatypesPurged(viewFilters);
  return {
    defaultAnalyticIntegration,
    ...sortData,
    ...dateRangeData,
    viewFilters: unselectedMediatypes,
    pageNumber,
  };
};

export const addParamsToBrowserUrl = (queryParams, location) => {
  const queryString = qs.stringify(queryParams, { indices: false });
  window.history.replaceState(
    null,
    '',
    `${window.location.pathname}#${location.pathname}?${queryString}`,
  );
};

export const getSelectedMediaTypes = (mediaTypes, mediaTypesToExclude) =>
  mediaTypes.reduce(
    (acc, mediaTypeApiKey) =>
      mediaTypesToExclude.includes(mediaTypeApiKey)
        ? acc
        : { ...acc, [mediaTypeApiKey]: mediaTypeApiKey },
    {},
  );

export const sendGTagRequest = (articles = [], searchId) => {
  if (articles.length === 0) {
    return;
  }

  const viewItemList = articles.flatMap(currentArticle => {
    const {
      author,
      dataSource,
      id,
      mediaType,
      publishDateAsDate,
      publisher,
      title,
      duplicatePublications,
      sourcechannelCode,
    } = currentArticle;

    if (duplicatePublications?.length) {
      return (
        duplicatePublications?.map(dupe => ({
          item_id: dupe.id,
          item_brand: dupe.publisher,
          item_name: dupe.title,
          item_category: dupe.dataSource,
          item_category2: dupe.author,
          item_category3: dupe.mediaType,
          item_category4: dupe.publishDateAsDate,
          item_category5: dupe.sourcechannelCode,
        })) || []
      );
    }

    return {
      item_id: id,
      item_brand: publisher,
      item_name: title,
      item_category: dataSource,
      item_category2: author,
      item_category3: mediaType,
      item_category4: publishDateAsDate,
      item_category5: sourcechannelCode,
    };
  });

  while (viewItemList.length) {
    gtag('event', gTagEventName.VIEW_ITEM_LIST, {
      account_id: getCurrentUserAccountId(),
      cision_user_id: getCurrentUserId(),
      impersonated: !!getCurrentUserImpersonating(),
      items: viewItemList.splice(0, 50),
    });
  }
};
