export default {
  toLabel: {
    id: 'EMAIL_FORM_TO_LABEL',
    defaultMessage: 'To',
    description:
      'Text to be displayed on the email composer > email-form for the To Label',
  },
  subjectLabel: {
    id: 'SUBJECT_FORM_TO_LABEL',
    defaultMessage: 'Subject',
    description:
      'Text to be displayed on the email composer > email-form for the Subject Label',
  },
  subjectRequiredLabel: {
    id: 'SUBJECT_REQUIRED_FORM_TO_LABEL',
    defaultMessage: 'Subject (Required)',
    description:
      'Text to be displayed on the email composer > email-form for the Subject Required Label',
  },
  CC: {
    id: 'SUBJECT_FORM_CC_LABEL',
    defaultMessage: 'Cc',
    description:
      'Text to be displayed on the email composer > email-form for the CC Label',
  },
  BCC: {
    id: 'SUBJECT_FORM_BCC_LABEL',
    defaultMessage: 'Bcc',
    description:
      'Text to be displayed on the email composer > email-form for the BCC Label',
  },
  requiredErrorMessage: {
    id: 'FORM_REQUIRED_ERROR_MESSAGE',
    defaultMessage: 'Required',
    description: 'Text to be displayed when a required error occurs',
  },
  invalidEmailErrorMessage: {
    id: 'FORM_INVALID_EMAIL_ERROR_MESSAGE',
    defaultMessage: 'Invalid email address',
    description:
      'Text to be displayed when an invalid email address error occurs',
  },
  attachmentFileSizeErrorMessage: {
    id: 'FORM_ATTACHMENT_FILE_SIZE_ERROR_MESSAGE',
    defaultMessage:
      'Your message has exceeded the 25 MB limit. Please reduce your number of attachments.',
    description:
      'Text to be displayed when an attachment file size error occurs',
  },
  attachmentLoadedMessage: {
    id: 'FORM_ATTACHMENT_LOADED_MESSAGE',
    defaultMessage:
      'Some files have not completely uploaded yet. Please wait or remove the files',
    description:
      'Text to be displayed to indicate the user that the upload of its files is still in progress',
  },
  attachmentsErrored: {
    id: 'FORM_ATTACHMENTS_ERROR_RED_ERROR_MESSAGE',
    defaultMessage:
      'Some files did not upload successfully. Please remove those files.',
    description:
      'Text to be displayed when the upload of one or more of the attachments fails',
  },
};
