// ad equiv keys
const ONLINE_NEWS_KEY = 'onlineNews';
const BLOGS_KEY = 'blogs';
const TELEVISION_KEY = 'television';
const PRINT_KEY = 'print';

// social amp keys
const PINTEREST_KEY = 'socialPinterest';
const FACEBOOK_KEY = 'socialFacebook';
const REDDIT_KEY = 'socialReddit';
const TWITTER_KEY = 'socialTwitter';

const SOCIAL_CHANNEL_NAME_MAP = {
  [PINTEREST_KEY]: 'Pinterest',
  [FACEBOOK_KEY]: 'Facebook',
  [REDDIT_KEY]: 'Reddit',
  [TWITTER_KEY]: 'Twitter',
};

const MEDIA_TYPE_NAME_MAP = {
  [ONLINE_NEWS_KEY]: 'Online News',
  [BLOGS_KEY]: 'Blogs',
  [PRINT_KEY]: 'Print',
  [TELEVISION_KEY]: 'Television',
};

const SOCIAL_AMP_ICON_MAP = {
  [PINTEREST_KEY]: 'pinterest',
  [FACEBOOK_KEY]: 'facebook',
  [REDDIT_KEY]: 'reddit',
  [TWITTER_KEY]: 'twitter',
};

const MEDIA_TYPE_ICON_MAP = {
  [ONLINE_NEWS_KEY]: null,
  [BLOGS_KEY]: null,
  [PRINT_KEY]: null,
  [TELEVISION_KEY]: null,
};

const NEWS_MEDIA_TYPE = 'NEWS';
const BLOGS_MEDIA_TYPE = 'BLOG';
const TV_MEDIA_TYPE = 'BROADCAST';
const PRINT_MEDIA_TYPE = 'PRINT';

export const MEDIA_TYPE_AVE_WIDGET = [
  NEWS_MEDIA_TYPE,
  BLOGS_MEDIA_TYPE,
  TV_MEDIA_TYPE,
  PRINT_MEDIA_TYPE,
];

export const BAR_CHART_METRIC_MAP = {
  SOCIAL_AMPLIFICATION: {
    channels: SOCIAL_CHANNEL_NAME_MAP,
    dimension: 'SOCIAL_PLATFORM',
    icons: SOCIAL_AMP_ICON_MAP,
    widgetIcon: 'share',
  },
  AVE: {
    channels: MEDIA_TYPE_NAME_MAP,
    dimension: 'MEDIA_TYPE',
    icons: MEDIA_TYPE_ICON_MAP,
    widgetIcon: 'dollar-sign',
  },
  ARTICLE_MENTION: {
    channels: '', // Channels will be provided by configured key messages
    dimension: 'SUB_SEARCH',
    widgetIcon: 'horizontalBarChartIcon',
  },
  TOTAL_READERSHIP: {
    channels: MEDIA_TYPE_NAME_MAP,
    dimension: 'MEDIA_TYPE',
    icons: MEDIA_TYPE_ICON_MAP,
    widgetIcon: 'listAlt',
  },
  AVG_ARTICLE_IMPACT: {
    channels: null,
    dimension: 'PUBLICATION',
    icons: {},
    widgetIcon: 'dashboardTiles',
  },
};

export const BAR_CHART_PLACEHOLDER_METRIC_MAP = {
  AVG_ARTICLE_IMPACT: {
    channels: ['ABC', 'BBC News', 'CNN', 'Forbes', 'Interesting Engineering'],
  },
};

export const METRIC_USING_BAR_CHART_MAP = [
  'SOCIAL_AMPLIFICATION',
  'AVE',
  'TOTAL_READERSHIP',
];

export const DASHBOARD_GRID_LAYOUT = {
  breakpoints: {
    lg: 'lg',
    sm: 'sm',
  },
  columns: {
    lg: 3,
    sm: 1,
  },
};
