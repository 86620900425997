import React, { Component } from 'react';

import { Button } from '@cision/rover-ui';

import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { Errors } from 'react-redux-form';

import { Media, SvgIcon, Error, TemplateString } from '@trendkite/ui';

import TranslatedMessage from 'i18n/TranslatedMessage';

import formMessages from '../email-form.messages';
import EmailRecipientsControl, {
  EmailRecipientsPlaceholder,
} from '../email-recipients-control';
import EmailSelectControl from '../email-select-control';
import EmailSubjectControl from '../email-subject-control';

class EmailHeaderGroup extends Component {
  static baseClass = 'email-header-group';

  state = {
    areCcsVisible: false,
    areBccsVisible: false,
  };

  clearCcs = () => {
    this.props.clearCcs();
    this.toggleCcsVisibility();
  };

  toggleCcsVisibility = () => {
    this.setState(prevState => ({ areCcsVisible: !prevState.areCcsVisible }));
  };

  clearBccs = () => {
    this.props.clearBccs();
    this.toggleBccsVisibility();
  };

  toggleBccsVisibility = () => {
    this.setState(prevState => ({ areBccsVisible: !prevState.areBccsVisible }));
  };
  errorMessages = {
    to: {
      required: this.props.intl.formatMessage(
        formMessages.requiredErrorMessage,
      ),
      validEmail: this.props.intl.formatMessage(
        formMessages.invalidEmailErrorMessage,
      ),
    },
    from: {
      required: this.props.intl.formatMessage(
        formMessages.requiredErrorMessage,
      ),
      validEmail: this.props.intl.formatMessage(
        formMessages.invalidEmailErrorMessage,
      ),
    },
    subject: {
      required: this.props.intl.formatMessage(
        formMessages.requiredErrorMessage,
      ),
    },
    cc: {
      validEmailAddress: this.props.intl.formatMessage(
        formMessages.invalidEmailErrorMessage,
      ),
    },
    bcc: {
      validEmailAddress: this.props.intl.formatMessage(
        formMessages.invalidEmailErrorMessage,
      ),
    },
    attachments: {
      filesSize: this.props.intl.formatMessage(
        formMessages.attachmentFileSizeErrorMessage,
      ),
      attachmentsLoaded: this.props.intl.formatMessage(
        formMessages.attachmentLoadedMessage,
      ),
      attachmentsErrored: this.props.intl.formatMessage(
        formMessages.attachmentsErrored,
      ),
    },
  };

  Error = ({ children }) => {
    return (
      <Error block padding="" paddingTop="5px">
        {children}
      </Error>
    );
  };

  getEmailControl = () => {
    const { optionsFrom } = this.props;
    if (optionsFrom.length === 1) {
      return <div className="t-m-3">{`From: ${optionsFrom[0].email}`}</div>;
    }

    return (
      <>
        <EmailSelectControl
          model=".from"
          options={optionsFrom}
          placeholder="Select reply-to email"
        />
        <Errors
          className="errors"
          component={this.Error}
          messages={this.errorMessages.from}
          model=".from"
          show
        />
      </>
    );
  };

  render() {
    const {
      className,
      onToFieldBlur,
      placeholderTo,
      intl,
      isSubjectFieldValid,
      isSubjectFieldTouched,
    } = this.props;

    const { areCcsVisible, areBccsVisible } = this.state;

    return (
      <div className={`${EmailHeaderGroup.baseClass} ${className}`}>
        <div
          className={`${EmailHeaderGroup.baseClass}__control ${EmailHeaderGroup.baseClass}__control--from`}
        >
          {this.getEmailControl()}
        </div>
        <div
          className={`${EmailHeaderGroup.baseClass}__control ${EmailHeaderGroup.baseClass}__control--to`}
        >
          <Media>
            <Media.Body>
              {placeholderTo && (
                <EmailRecipientsPlaceholder
                  label={intl.formatMessage(formMessages.toLabel)}
                >
                  <div
                    className={`${EmailHeaderGroup.baseClass}__placeholder-template-string`}
                  >
                    <TemplateString>{placeholderTo}</TemplateString>
                  </div>
                </EmailRecipientsPlaceholder>
              )}
              {!placeholderTo && (
                <React.Fragment>
                  <EmailRecipientsControl
                    inputTextDisabled
                    label={`${intl.formatMessage(formMessages.toLabel)}:`}
                    min={1}
                    model=".to"
                    onBlur={onToFieldBlur}
                  />
                  <Errors
                    className="errors"
                    component={this.Error}
                    messages={this.errorMessages.to}
                    model=".to"
                    show="touched"
                  />
                </React.Fragment>
              )}
            </Media.Body>
            {(!areCcsVisible || !areBccsVisible) && (
              <Media.Item>
                <div className={`${EmailHeaderGroup.baseClass}__actions`}>
                  <Media>
                    {!areCcsVisible && (
                      <Media.Item>
                        <Button
                          data-qa="BNA4mALh22_tOWXQlrtdd"
                          level="text"
                          size="sm"
                          onClick={this.toggleCcsVisibility}
                          style={{ padding: '0 5px' }}
                        >
                          <TranslatedMessage {...formMessages.CC} />
                        </Button>
                      </Media.Item>
                    )}
                    {!areBccsVisible && (
                      <Media.Item>
                        <Button
                          data-qa="YEKuAJmJr0-lJB0bltxqX"
                          level="text"
                          size="sm"
                          onClick={this.toggleBccsVisibility}
                          style={{ padding: '0 5px' }}
                        >
                          <TranslatedMessage {...formMessages.BCC} />
                        </Button>
                      </Media.Item>
                    )}
                  </Media>
                </div>
              </Media.Item>
            )}
          </Media>
        </div>
        {areCcsVisible && (
          <div
            className={`${EmailHeaderGroup.baseClass}__control ${EmailHeaderGroup.baseClass}__control--ccs`}
          >
            <Media>
              <Media.Body>
                <EmailRecipientsControl
                  autoFocus // eslint-disable-line jsx-a11y/no-autofocus
                  label="Cc"
                  model=".cc"
                />
                <Errors
                  className="errors"
                  component={this.Error}
                  messages={this.errorMessages.cc}
                  model=".cc"
                  show="touched"
                />
              </Media.Body>
              <Media.Item>
                <div
                  className={`${EmailHeaderGroup.baseClass}__actions ${EmailHeaderGroup.baseClass}__actions--opened`}
                >
                  <Button
                    data-qa="VjlG2_DJo39X_5xDjm3IK"
                    level="text"
                    size="sm"
                    onClick={this.clearCcs}
                    style={{ padding: '0 5px' }}
                  >
                    <SvgIcon icon="cross" />
                  </Button>
                </div>
              </Media.Item>
            </Media>
          </div>
        )}
        {areBccsVisible && (
          <div
            className={`${EmailHeaderGroup.baseClass}__control ${EmailHeaderGroup.baseClass}__control--bccs`}
          >
            <Media>
              <Media.Body>
                <EmailRecipientsControl
                  autoFocus // eslint-disable-line jsx-a11y/no-autofocus
                  label="Bcc"
                  model=".bcc"
                />
                <Errors
                  className="errors"
                  component={this.Error}
                  messages={this.errorMessages.bcc}
                  model=".bcc"
                  show="touched"
                />
              </Media.Body>
              <Media.Item>
                <div
                  className={`${EmailHeaderGroup.baseClass}__actions ${EmailHeaderGroup.baseClass}__actions--opened`}
                >
                  <Button
                    data-qa="Uw-r8thV6jg3qv0nJs3AH"
                    level="text"
                    size="sm"
                    onClick={this.clearBccs}
                    style={{ padding: '0 5px' }}
                  >
                    <SvgIcon icon="cross" />
                  </Button>
                </div>
              </Media.Item>
            </Media>
          </div>
        )}
        <div
          className={`${EmailHeaderGroup.baseClass}__control ${EmailHeaderGroup.baseClass}__control--subject`}
        >
          <EmailSubjectControl
            model=".subject"
            isSubjectFieldValid={isSubjectFieldValid}
            isSubjectFieldTouched={isSubjectFieldTouched}
          />
        </div>
      </div>
    );
  }
}

EmailHeaderGroup.propTypes = {
  clearBccs: PropTypes.func.isRequired,
  clearCcs: PropTypes.func.isRequired,
  className: PropTypes.string,
  onToFieldBlur: PropTypes.func,
  intl: PropTypes.object.isRequired,
  isSubjectFieldValid: PropTypes.bool,
  isSubjectFieldTouched: PropTypes.bool,
  optionsFrom: PropTypes.arrayOf(PropTypes.object).isRequired,
  placeholderTo: PropTypes.string,
};

EmailHeaderGroup.defaultProps = {
  className: '',
  onToFieldBlur: () => {},
  placeholderTo: '',
  isSubjectFieldValid: false,
  isSubjectFieldTouched: false,
};

export default injectIntl(EmailHeaderGroup);
