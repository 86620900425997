export default {
  outreachHeader: {
    id: 'EMAIL_SETTINGS_OUTREACH_HEADER',
    defaultMessage: 'Email Tracking',
    description: 'Used as the header for outreach settings',
  },
  outreachAltHeader: {
    id: 'EMAIL_SETTINGS_OUTREACH_ALT_HEADER',
    defaultMessage: 'Integrated Email Accounts',
    description: 'Used as a header for outreach settings',
  },
  outreachAltSendgridPitchHeader: {
    id: 'EMAIL_SETTINGS_OUTREACH_ALT_SENGRID_PITCH_HEADER',
    defaultMessage: 'Verified Email Accounts',
    description: 'Used as a header for outreach settings',
  },
  outreachSubHeader: {
    id: 'EMAIL_SETTINGS_OUTREACH_SUB_HEADER',
    defaultMessage: 'Email Accounts',
    description: 'Used as the sub header for outreach settings.',
  },
  outreachDescription: {
    id: 'EMAIL_SETTINGS_OUTREACH_DESCRIPTION',
    defaultMessage:
      'Connect to your email provider to send emails directly from {COMPANY_NAME}.',
    description: 'Used as the description for outreach settings.',
  },
  outreachAltDescription: {
    id: 'EMAIL_SETTINGS_OUTREACH_ALT_DESCRIPTION',
    defaultMessage:
      'Connect your email to Cision and send emails directly from the platform.',
    description: 'Used as a description for outreach settings',
  },
  outreachAddAccount: {
    id: 'EMAIL_SETTINGS_OUTREACH_ADD_ACCOUNT',
    defaultMessage: 'Add Account',
    description: 'Used as a button to add an account',
  },
  outreachAltAddAccount: {
    id: 'EMAIL_SETTINGS_OUTREACH_ALT_ADD_ACCOUNT',
    defaultMessage: 'Add Email Account',
    description: 'Used as an alternate text for the add an account button',
  },
  outreachAltSendgridPitchAddAccount: {
    id: 'EMAIL_SETTINGS_OUTREACH_ALT_SENDGRID_PITCH_ADD_ACCOUNT',
    defaultMessage: 'Verify Email Account',
    description: 'Used as an alternate text for the add an account button',
  },
  outreachYourAccounts: {
    id: 'EMAIL_SETTINGS_OUTREACH_YOUR_ACCOUNTS',
    defaultMessage: 'Your Accounts',
    description: 'Used as a header for the outreach integration tables.',
  },
  outreachTeamAccounts: {
    id: 'EMAIL_SETTINGS_OUTREACH_TEAM_ACCOUNTS',
    defaultMessage: 'Team Accounts',
    description: 'Used as a header for the outreach integration tables.',
  },
  outreachDisconnectHeader: {
    id: 'EMAIL_SETTINGS_OUTREACH_DISCONNECT_HEADER',
    defaultMessage: 'Disconnect this email account?',
    description:
      'Used as a header when disconnecting an account on the confirmation modal.',
  },
  outreachDisconnectButton: {
    id: 'EMAIL_SETTINGS_OUTREACH_DISCONNECT_BUTTON',
    defaultMessage: 'Yes, Disconnect',
    description:
      'Used as a button to disconnect an account on the confirmation modal.',
  },
  optOutHeader: {
    id: 'EMAIL_SETTINGS_OPT_OUT_HEADER',
    defaultMessage: 'Opt Out Address',
    description: 'Used as the header for opt out settings.',
  },
  optOutSubHeader: {
    id: 'EMAIL_SETTINGS_OPT_OUT_SUB_HEADER',
    defaultMessage: 'Current Address',
    description: 'Used as the sub header for opt out settings.',
  },
  optOutDescription: {
    id: 'EMAIL_SETTINGS_OPT_OUT_DESCRIPTION',
    defaultMessage: 'Set your opt out address for this account.',
    description: 'Used as the description for opt out settings.',
  },
  optOutEditAddress: {
    id: 'EMAIL_SETTINGS_OPT_OUT_EDIT_ADDRESS',
    defaultMessage: 'Edit Address',
    description: 'Used as a button to edit address.',
  },
  optOutSaveChanges: {
    id: 'EMAIL_SETTINGS_OPT_OUT_SAVE_CHANGES',
    defaultMessage: 'Save Changes',
    description: 'Used as a button to save changes on editor.',
  },
  outreachBlockListHeader: {
    id: 'EMAIL_SETTINGS_BLOCK_LIST_HEADER',
    defaultMessage: 'Email Integration Block List',
    description: 'Used as the header for the block list section',
  },
  outreachCalloutInfoMessage: {
    id: 'EMAIL_SETTINGS_CALLOUT_INFO_MESSAGE',
    defaultMessage:
      'One or more email domains within this account are not authenticated and could be increasing your bounce rate. <a>Contact support</a>',
    description: 'Text for the callout info message',
  },
  outreachCalloutInfoMessageInProgress: {
    id: 'EMAIL_SETTINGS_CALLOUT_INFO_MESSAGE_IN_PROGRESS',
    defaultMessage:
      'One or more email domains within this account are not authenticated and could be increasing your bounce rate. <a>DKIM Setup in progress, chat with support</a>',
    description: 'Text for the in progress callout info message',
  },
  outreachCalloutInfoMessageEmailSettings: {
    id: 'EMAIL_SETTINGS_CALLOUT_INFO_MESSAGE_BANNER',
    defaultMessage:
      'One or more verified email addresses in this account are not protected from email delivery issues. <a>Contact Support to start the Domain Authentication Process</a>',
    description: 'Text for the callout info message',
  },
  outreachCalloutInfoMessageInProgressEmailSettings: {
    id: 'EMAIL_SETTINGS_CALLOUT_INFO_MESSAGE_IN_PROGRESS_BANNER',
    defaultMessage:
      'One or more verified email addresses in this account are not protected from email delivery issues. <a>Setup currently in progress, check status with Support team.</a>',
    description: 'Text for the in progress callout info message',
  },
  defaultKiteErrorMessage: {
    id: 'EMAIL_SETTINGS_DEFAULT_KITE_ERROR_MESSAGE',
    defaultMessage:
      'Hmm, something went wrong with the request. Please try again later.',
    description: 'Text for the default kite danger message',
  },
  noNewDomainsKiteErrorMessage: {
    id: 'EMAIL_SETTINGS_DEFAULT_NO_NEW_DOMAINS_KITE_ERROR_MESSAGE',
    defaultMessage:
      'There are no new domains to request. Your email to support will not be sent.',
    description: 'Text for the no new domains kite danger message',
  },
  emailSettingsHeaderEmailAddress: {
    id: 'EMAIL_SETTING_HEADER_EMAIL_ADDRESS',
    defaultMessage: 'Email Address',
    description: 'Text for email settings page header for email addresses',
  },
  emailSettingsHeaderAccountOwner: {
    id: 'EMAIL_SETTING_HEADER_ACCOUNT_OWNER',
    defaultMessage: 'Account Owner',
    description: 'Text for email settings page header for account owners',
  },
  emailSettingsHeaderIntegrationStatus: {
    id: 'EMAIL_SETTING_HEADER_INTEGRATION_STATUS',
    defaultMessage: 'Integration Status',
    description: 'Text for email settings page header for integration status',
  },
  emailSettingsHeaderLastSynced: {
    id: 'EMAIL_SETTING_HEADER_LAST_SYNCED',
    defaultMessage: 'Last Synced',
    description: 'Text for email settings page header for last synced',
  },
  emailSettingsLegalModalTitle: {
    id: 'EMAIL_SETTINGS_LEGAL_MODAL_TITLE',
    defaultMessage: 'Email Integration: Before You Continue',
    description: 'Text used for the legal modal title',
  },
  emailSettingsLegalModalBody: {
    id: 'EMAIL_SETTINGS_LEGAL_MODAL_BODY_TEXT',
    defaultMessage: `You are linking your own separate email service to you or your company's
    Cision account (the "Integrated Email"). This means that Cision and/or its third-party
    service providers will search all emails in the Integrated Email in order to index, copy,
    store and display emails related to influencers listed in the Cision media database in you or
    your company's Cision Platform account, including custom influencers created in you and your
    Cision Platform account's Private Contacts list. Other users on the account will be able to
    see the emails that are brought into the Platform from the Integrated Email. You represent
    and warrant that:{br}{br}
    (1) You have the authority to integrate your email service with the Cision Platform.{br}{br}
    (2) You have all rights and permissions necessary to grant Cision permission to search, index
    and copy email messages from the designated email account and to store and display the email
    messages in the Platform.{br}{br}
    (3) Integration of your own email will not violate any of your company's internal policies.{br}{br}
    Email integration is subject to additional terms included in your contract with Cision.`,
    description: 'Text used for the legal modal body',
  },
  emailSettingsHeaderLinkTracking: {
    id: 'EMAIL_SETTINGS_LINK_TRACKING_HEADER',
    defaultMessage: 'LINK TRACKING',
    description: 'Text used for the link tracking header',
  },
  linkTrackingToolTip: {
    id: 'LINK_TRACKING_TOOLTIP',
    defaultMessage:
      'Enabling Link Tracking will replace any links you’ve added to your message ' +
      'with new links generated by Sendgrid. This allows us to give you statistics on how many of your message recipients click on your added links.',
    description: 'Tooltip for link tracking toggle',
  },
};
