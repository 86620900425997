import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { Control } from 'react-redux-form';

import { Select } from '@trendkite/ui';

import FromSelect from './FromSelect';

const CustomSelect = props => (
  <Select
    {...props}
    buttonProps={{ style: { paddingLeft: '10px', paddingRight: '20px' } }}
    listMaxHeight="180px"
    modifiers={['seethrough']}
  />
);

class EmailSelectControl extends Component {
  static baseClass = 'email-select-control';

  render() {
    const { className, model, multiple, options, placeholder } = this.props;

    return (
      <div className={`${EmailSelectControl.baseClass} ${className}`}>
        <Control
          betterSelectMode
          component={model === '.from' ? FromSelect : CustomSelect}
          mapProps={{
            value: props => props.viewValue,
          }}
          model={`${model}`}
          multiple={multiple}
          options={options}
          placeholder={placeholder}
        />
      </div>
    );
  }
}

EmailSelectControl.propTypes = {
  className: PropTypes.string,
  model: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.object,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      label: PropTypes.string,
    }),
  ),
  placeholder: PropTypes.string,
};

EmailSelectControl.defaultProps = {
  className: '',
  multiple: false,
  options: [],
  placeholder: '',
};

export default EmailSelectControl;
