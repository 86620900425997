import { produce } from 'immer';
import castArray from 'lodash/castArray';
import differenceBy from 'lodash/differenceBy';
import filter from 'lodash/filter';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import first from 'lodash/first';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import omit from 'lodash/omit';
import reject from 'lodash/reject';
import some from 'lodash/some';
import unionWith from 'lodash/unionWith';
import upperFirst from 'lodash/upperFirst';
import values from 'lodash/values';

import {
  INFLUENCER_HUB_ENDPOINT,
  INFLUENCER_SAVED_LISTS_LIST_MEMBERS_ENDPOINT,
  INFLUENCER_GET_PRIVATE_INFLUENCERS_URL,
  INFLUENCER_GET_UNSUBSCRIBED_INFLUENCERS_URL,
} from 'constants/apis';
import {
  IHUB_ADVANCED_SEARCH_TYPES,
  IHUB_SORT_BY_TYPES,
  FEATURES,
} from 'constants/constants';
import {
  getCanUseInfluencerDisplayTargetArea,
  getCanUseSuggestionsFECaching,
} from 'selectors/canUse/canUse';
import { activeLanguageSelector } from 'selectors/user-settings';
import { userHasFeatureFlag } from 'services/feature-service/feature-service';
import {
  performGet,
  performPost,
  getCancelTokenSource,
  request,
  isRequestCancel,
} from 'services/rest-service/rest-service';

import {
  INFLUENCERS_BULK_UNSELECT_ALL_INFLUENCERS,
  fetchNewInfluencers,
  INFLUENCERS_ON_SEARCH,
  bulkMultiSelectAllInfluencers,
} from '../InfluencerList/reducers/search';
import {
  getPreviewActionDispatcher,
  getPreviewActionByIdDispatcher,
} from '../reducers/preview';

import {
  FILTER_SECTION_CONFIG,
  SEARCH_CRITERIA_NAMES as INFLUENCERS_HUB_SEARCH_CRITERIA_NAMES,
  SEARCH_SECTION_NAMES as INFLUENCERS_HUB_SEARCH_SECTION_NAMES,
  SEARCH_INFO_TYPE as INFLUENCERS_HUB_SEARCH_INFO_TYPE,
} from './constants';
import {
  countFilters,
  fullSearchCriteriaSelector,
  getSearchPageSelector,
  influencerCriteriaSelector,
  isInfluencerOnlySearchSelector,
  isOutletOnlySearchSelector,
  isSearchDisabledSelector,
  pillSectionFilterLanguagesSelector,
  pillSectionFilterLocationsSelector,
  pillSectionFilterOutletsSelector,
  pillSectionFilterTopicsSelector,
  topicsSearchTextSelector,
} from './selectors';
import {
  getCriteria,
  getInfluencerTypeByCriteria,
  getSearchCriteriaForInfluencerType,
  getUniquePills,
  influencerPreviewToData,
  outletPreviewToData,
  sortPills,
} from './utils';

export const CLEAR_SEARCH = 'influencer-hub/CLEAR_SEARCH';
export const PERSIST_APPLIED_FILTERS_ON_PAGE_LEAVE =
  'influencer-hub/PERSIST_APPLIED_FILTERS_ON_PAGE_LEAVE';
export const CLEAR_PRIVATE_INFLUENCER_MATCHES =
  'influencer-hub/CLEAR_PRIVATE_INFLUENCER_MATCHES';
export const GET_SEARCH_RESULTS = 'influencer-hub/GET_SEARCH_RESULTS';
export const GET_SEARCH_RESULTS_ERROR =
  'influencer-hub/GET_SEARCH_RESULTS_ERROR';

export const GET_TOPICS = 'influencer-hub/GET_TOPICS';
export const GET_TOPICS_SUCCESS = 'influencer-hub/GET_TOPICS_SUCCESS';
export const GET_TOPICS_ERROR = 'influencer-hub/GET_TOPICS_ERROR';

export const GET_PILL_SECTION_TOPICS = 'influencer-hub/GET_PILL_SECTION_TOPICS';
export const GET_PILL_SECTION_TOPICS_SUCCESS =
  'influencer-hub/GET_PILL_SECTION_TOPICS_SUCCESS';
export const GET_PILL_SECTION_TOPICS_ERROR =
  'influencer-hub/GET_PILL_SECTION_TOPICS_ERROR';

export const GET_PILL_SECTION_FILTER_TOPICS =
  'influencer-hub/GET_PILL_SECTION_FILTER_TOPICS';
export const GET_PILL_SECTION_FILTER_TOPICS_SUCCESS =
  'influencer-hub/GET_PILL_SECTION_FILTER_TOPICS_SUCCESS';
export const GET_PILL_SECTION_FILTER_TOPICS_ERROR =
  'influencer-hub/GET_PILL_SECTION_FILTER_TOPICS_ERROR';

export const GET_PILL_SECTION_FILTER_LANGUAGE =
  'influencer-hub/GET_PILL_SECTION_FILTER_LANGUAGE';
export const GET_PILL_SECTION_FILTER_LANGUAGE_SUCCESS =
  'influencer-hub/GET_PILL_SECTION_FILTER_LANGUAGE_SUCCESS';
export const GET_PILL_SECTION_FILTER_LANGUAGE_ERROR =
  'influencer-hub/GET_PILL_SECTION_FILTER_LANGUAGE_ERROR';

export const GET_LOCATIONS = 'influencer-hub/GET_LOCATIONS';
export const GET_LOCATIONS_SUCCESS = 'influencer-hub/GET_LOCATIONS_SUCCESS';
export const GET_POPULAR_LOCATIONS_SUCCESS =
  'influencer-hub/GET_POPULAR_LOCATIONS_SUCCESS';
export const GET_LOCATIONS_ERROR = 'influencer-hub/GET_LOCATIONS_ERROR';

export const GET_PILL_SECTION_LOCATIONS =
  'influencer-hub/GET_PILL_SECTION_LOCATIONS';
export const GET_PILL_SECTION_LOCATIONS_SUCCESS =
  'influencer-hub/GET_PILL_SECTION_LOCATIONS_SUCCESS';
export const GET_PILL_SECTION_LOCATIONS_ERROR =
  'influencer-hub/GET_PILL_SECTION_LOCATIONS_ERROR';

export const GET_PILL_SECTION_FILTER_LOCATIONS =
  'influencer-hub/GET_PILL_SECTION_FILTER_LOCATIONS';
export const GET_PILL_SECTION_FILTER_LOCATIONS_SUCCESS =
  'influencer-hub/GET_PILL_SECTION_FILTER_LOCATIONS_SUCCESS';
export const GET_PILL_SECTION_FILTER_LOCATIONS_ERROR =
  'influencer-hub/GET_PILL_SECTION_FILTER_LOCATIONS_ERROR';

export const GET_OUTLETS = 'influencer-hub/GET_OUTLETS';
export const GET_OUTLETS_SUCCESS = 'influencer-hub/GET_OUTLETS_SUCCESS';
export const GET_POPULAR_OUTLETS_SUCCESS =
  'influencer-hub/GET_POPULAR_OUTLETS_SUCCESS';
export const GET_OUTLETS_ERROR = 'influencer-hub/GET_OUTLETS_ERROR';
export const RESET_OUTLETS = 'influencer-hub/RESET_OUTLETS';
export const RESET_POPULAR_OUTLETS = 'influencer-hub/RESET_POPULAR_OUTLETS';

export const GET_PILL_SECTION_OUTLETS =
  'influencer-hub/GET_PILL_SECTION_OUTLETS';
export const GET_PILL_SECTION_OUTLETS_SUCCESS =
  'influencer-hub/GET_PILL_SECTION_OUTLETS_SUCCESS';
export const GET_PILL_SECTION_OUTLETS_ERROR =
  'influencer-hub/GET_PILL_SECTION_OUTLETS_ERROR';

export const GET_PILL_SECTION_FILTER_OUTLETS =
  'influencer-hub/GET_PILL_SECTION_FILTER_OUTLETS';
export const GET_PILL_SECTION_FILTER_OUTLETS_SUCCESS =
  'influencer-hub/GET_PILL_SECTION_FILTER_OUTLETS_SUCCESS';
export const GET_PILL_SECTION_FILTER_OUTLETS_ERROR =
  'influencer-hub/GET_PILL_SECTION_FILTER_OUTLETS_ERROR';

export const GET_PILL_SECTION_FILTER_JOB_ROLES =
  'influencer-hub/GET_PILL_SECTION_FILTER_JOB_ROLES';
export const GET_PILL_SECTION_FILTER_JOB_ROLES_SUCCESS =
  'influencer-hub/GET_PILL_SECTION_FILTER_JOB_ROLES_SUCCESS';
export const GET_PILL_SECTION_FILTER_JOB_ROLES_ERROR =
  'influencer-hub/GET_PILL_SECTION_FILTER_JOB_ROLES_ERROR';

export const GET_INFLUENCERS = 'influencer-hub/GET_INFLUENCERS';
export const GET_INFLUENCERS_SUCCESS = 'influencer-hub/GET_INFLUENCERS_SUCCESS';
export const GET_INFLUENCERS_CANCELED =
  'influencer-hub/GET_INFLUENCERS_CANCELED';
export const GET_INFLUENCERS_ERROR = 'influencer-hub/GET_INFLUENCERS_ERROR';
export const GET_PRIVATE_INFLUENCER_MATCHES =
  'influencer-hub/GET_PRIVATE_INFLUENCER_MATCHES';
export const GET_POPULAR_INFLUENCERS_SUCCESS =
  'influencer-hub/GET_POPULAR_INFLUENCERS_SUCCESS';
export const GET_PRIVATE_INFLUENCER_MATCHES_SUCCESS =
  'influencer-hub/GET_PRIVATE_INFLUENCER_MATCHES_SUCCESS';
export const GET_PRIVATE_INFLUENCER_MATCHES_ERROR =
  'influencer-hub/GET_PRIVATE_INFLUENCER_MATCHES_ERROR';

export const REMOVE_INFLUENCERS_FROM_LIST =
  'influencer-hub/REMOVE_INFLUENCERS_FROM_LIST';
export const REMOVE_INFLUENCERS_FROM_LIST_SUCCESS =
  'influencer-hub/REMOVE_INFLUENCERS_FROM_LIST_SUCCESS';
export const REMOVE_INFLUENCERS_FROM_LIST_ERROR =
  'influencer-hub/REMOVE_INFLUENCERS_FROM_LIST_ERROR';

export const ADD_UPDATE_CHOSEN_TOPIC = 'influencer-hub/ADD_UPDATE_CHOSEN_TOPIC';
export const SET_CHOSEN_TOPICS = 'influencer-hub/SET_CHOSEN_TOPICS';
export const ADD_UPDATE_CHOSEN_LOCATION =
  'influencer-hub/ADD_UPDATE_CHOSEN_LOCATION';
export const SET_CHOSEN_LOCATIONS = 'influencer-hub/SET_CHOSEN_LOCATIONS';

export const REMOVE_CHOSEN_TOPIC = 'influencer-hub/REMOVE_CHOSEN_TOPIC';
export const REMOVE_CHOSEN_LOCATION = 'influencer-hub/REMOVE_CHOSEN_LOCATION';

export const ADD_UPDATE_CHOSEN_OUTLET =
  'influencer-hub/ADD_UPDATE_CHOSEN_OUTLET';
export const SET_CHOSEN_OUTLETS = 'influencer-hub/SET_CHOSEN_OUTLETS';
export const REMOVE_CHOSEN_OUTLET = 'influencer-hub/REMOVE_CHOSEN_OUTLET';

export const UPDATE_SEARCH_TEXT = 'influencer-hub/UPDATE_SEARCH_TEXT';
export const UPDATE_TOPIC_SEARCH_TEXT =
  'influencer-hub/UPDATE_TOPIC_SEARCH_TEXT';
export const UPDATE_LOCATION_SEARCH_TEXT =
  'influencer-hub/UPDATE_LOCATION_SEARCH_TEXT';
export const UPDATE_OUTLET_SEARCH_TEXT =
  'influencer-hub/UPDATE_OUTLET_SEARCH_TEXT';

export const START_FREE_SEARCH_TEXT_MODE =
  'influencer-hub/START_FREE_SEARCH_TEXT_MODE';
export const CANCEL_FREE_SEARCH_TEXT_MODE =
  'influencer-hub/CANCEL_FREE_SEARCH_TEXT_MODE';
export const START_TOPIC_SEARCH_TEXT_MODE =
  'influencer-hub/START_TOPIC_SEARCH_TEXT_MODE';
export const CANCEL_TOPIC_SEARCH_TEXT_MODE =
  'influencer-hub/CANCEL_TOPIC_SEARCH_TEXT_MODE';
export const START_LOCATION_SEARCH_TEXT_MODE =
  'influencer-hub/START_LOCATION_SEARCH_TEXT_MODE';
export const CANCEL_LOCATION_SEARCH_TEXT_MODE =
  'influencer-hub/CANCEL_LOCATION_SEARCH_TEXT_MODE';
export const START_OUTLET_SEARCH_TEXT_MODE =
  'influencer-hub/START_OUTLET_SEARCH_TEXT_MODE';
export const CANCEL_OUTLET_SEARCH_TEXT_MODE =
  'influencer-hub/CANCEL_OUTLET_SEARCH_TEXT_MODE';

export const UPDATE_INFLUENCER_TYPE_SEARCH_CRITERIA =
  'influencer-hub/UPDATE_INFLUENCER_TYPE_SEARCH_CRITERIA';
export const UPDATE_TOPIC_SEARCH_CRITERIA =
  'influencer-hub/UPDATE_TOPIC_SEARCH_CRITERIA';
export const UPDATE_LOCATION_SEARCH_CRITERIA =
  'influencer-hub/UPDATE_LOCATION_SEARCH_CRITERIA';
export const UPDATE_OUTLET_SEARCH_CRITERIA =
  'influencer-hub/UPDATE_OUTLET_SEARCH_CRITERIA';
export const UPDATE_SEARCH_RESULTS_TOTAL_HITS =
  'influencer-hub/UPDATE_SEARCH_RESULTS_TOTAL_HITS';

export const SET_CONTACT_ATTRIBUTES_IDS =
  'influencer-hub/SET_CONTACT_ATTRIBUTES_IDS';
export const ADD_SUBSCRIBED_FILTER = 'influencer-hub/ADD_SUBSCRIBED_FILTER';
export const REMOVE_SUBSCRIBED_FILTER =
  'influencer-hub/REMOVE_SUBSCRIBED_FILTER';

export const ADD_ADVANCED_SEARCH_CRITERIA =
  'influencer-hub/ADD_ADVANCED_SEARCH_CRITERIA';
export const REMOVE_ADVANCED_SEARCH_CRITERIA =
  'influencer-hub/REMOVE_ADVANCED_SEARCH_CRITERIA';
export const CLEAR_ADVANCED_SEARCH_CRITERIA =
  'influencer-hub/CLEAR_ADVANCED_SEARCH_CRITERIA';

export const SET_SEARCH_AGGREGATIONS = 'influencer-hub/SET_SEARCH_AGGREGATIONS';

export const CHANGE_PAGE_NUMBER = 'influencer-hub/CHANGE_PAGE_NUMBER';

export const SET_SORT_FIELD = 'influencer-hub/SET_SORT_FIELD';
export const SET_SORT_DIRECTION = 'influencer-hub/SET_SORT_DIRECTION';

export const UPDATE_SEARCH_BY_CONTACT_NAME =
  'influencer-hub/UPDATE_SEARCH_BY_CONTACT_NAME';

export const SET_TOPIC_FILTER = 'influencer-hub/SET_TOPIC_FILTER';
export const CLEAR_TOPIC_FILTER = 'influencer-hub/CLEAR_TOPIC_FILTER';

export const SET_LOCATION_FILTER = 'influencer-hub/SET_LOCATION_FILTER';
export const CLEAR_LOCATION_FILTER = 'influencer-hub/CLEAR_LOCATION_FILTER';

export const SET_OUTLET_FILTER = 'influencer-hub/SET_OUTLET_FILTER';
export const CLEAR_OUTLET_FILTER = 'influencer-hub/CLEAR_OUTLET_FILTER';

export const SET_JOB_ROLE_FILTER = 'influencer-hub/SET_JOB_ROLE_FILTER';
export const CLEAR_JOB_ROLE_FILTER = 'influencer-hub/CLEAR_JOB_ROLE_FILTER';
export const SET_JOB_ROLE_FILTER_SIMPLE =
  'influencer-hub/SET_JOB_ROLE_FILTER_SIMPLE';

export const SET_LANGUAGE_FILTER = 'influencer-hub/SET_LANGUAGE_FILTER';
export const CLEAR_LANGUAGE_FILTER = 'influencer-hub/CLEAR_LANGUAGE_FILTER';

export const UPDATE_REACH_AND_IMPACT_FILTER =
  'influencer-hub/UPDATE_REACH_AND_IMPACT_FILTER';
export const CLEAR_REACH_AND_IMPACT_FILTER =
  'influencer-hub/CLEAR_REACH_AND_IMPACT_FILTER';

export const SET_SELECTED_LIST_ID = 'influencer-hub/SET_SELECTED_LIST_ID';
export const RESET_SELECTED_LIST_ID = 'influencer-hub/RESET_SELECTED_LIST_ID';
export const SET_ACTIVE_INFLUENCER_PAGE_TAB =
  'influencer-hub/SET_ACTIVE_INFLUENCER_PAGE_TAB';
export const CLEAR_ACTIVE_INFLUENCER_TAB_ON_PAGE_LEAVE =
  'influencer-hub/CLEAR_ACTIVE_INFLUENCER_TAB_ON_PAGE_LEAVE';

export const UPDATE_SEARCH_BY_OUTLET_NAME =
  'influencer-hub/UPDATE_SEARCH_BY_OUTLET_NAME';

const createSearchSection = props => ({
  data: [],
  loading: false,
  error: false,
  ...props,
});

const UNSUBSCRIBED_INFLUENCERS_ID = 0;
export const initialState = {
  pageState: {
    activeInfluencerPageTab: 'OVERVIEW',
    contactAttributesIds: [UNSUBSCRIBED_INFLUENCERS_ID],
    freeTextSearch: '',
    freeTextInputMode: false,
    searchCriteria: {
      pagesize: 50,
      pagenumber: 1,
      contact: [],
      outlet: [],
      topic: [],
      language: [],
      location: [],
      jobRole: [],
      talkingAbout: [],
      unsubscribed: [],
      influencerType: [],
      mediaType: [],
      frequency: [],
      newsFocus: [],
      articleReach: null,
      socialReach: null,
      sort: 'relevance',
      sortOrder: 'asc',
      aggsize: 0,
      aggsInclude: [],
    },
    topics: {
      chosen: [],
      textInputMode: false,
      searchText: '',
    },
    locations: {
      chosen: [],
      textInputMode: false,
      searchText: '',
    },
    outlets: {
      chosen: [],
      textInputMode: false,
      searchText: '',
    },
    componentTitles: {
      topicTitle: 'Topics',
      locationTitle: 'Locations',
      influencerTitle: 'Recently Viewed Contacts',
      outletTitle: 'Recently Viewed Outlets',
    },
    selectedListId: null,
  },
  searchResults: {
    topics: createSearchSection({ popular: true }),
    pillSectionTopics: createSearchSection(),
    pillSectionFilterTopics: createSearchSection(),
    locations: createSearchSection({ popular: true }),
    pillSectionLocations: createSearchSection(),
    pillSectionFilterLocations: createSearchSection(),
    outlets: createSearchSection({ popular: true }),
    pillSectionOutlets: createSearchSection(),
    pillSectionFilterOutlets: createSearchSection(),
    pillSectionFilterJobRoles: createSearchSection(),
    pillSectionFilterLanguages: createSearchSection(),
    influencers: createSearchSection({ popular: true }),
    recentOutlets: createSearchSection(),
    privateInfluencerMatches: createSearchSection({ popular: true }),
    totalResults: 0,
    currentPageNumber: 0,
    totalPages: 0,
    aggregations: [],
  },
};

const addUpdateChosenItem = (chosenItems, existingItem) => {
  const updatedItems = [...chosenItems];

  if (existingItem) {
    const existingItemIndex = findIndex(updatedItems, {
      id: existingItem.id,
    });

    // The change of booleanCondition or adding a new topic is done this way to preserve the
    // position of the pills in the array. Changing the position causes the UI to make the
    // pills jump around. It also alters the way the criteria is presented with its conditionals
    // to the API for searching.
    if (existingItemIndex === -1) {
      updatedItems.push(existingItem);
    } else {
      updatedItems[existingItemIndex].booleanCondition =
        existingItem.booleanCondition;
    }
  }

  return updatedItems;
};

const removeResetChosenItems = (chosenItems, removedItemId) => {
  const filteredItems = chosenItems.filter(topic => topic.id !== removedItemId);

  if (filteredItems.length === 1) {
    filteredItems[0].booleanCondition = 'OR';
  }

  return filteredItems;
};

const updateSearchResultSubSection = ({
  section,
  state: currentState,
  loading = false,
  error = false,
  popular = false,
  data = [],
}) => {
  return {
    ...currentState,
    searchResults: {
      ...currentState.searchResults,
      [section]: {
        data,
        loading,
        error,
        popular,
      },
    },
  };
};

const updateRemoveInfluencerFromList = ({
  section,
  state: currentState,
  loading = false,
  error = false,
  payload = null,
}) => {
  const currentData = currentState.searchResults[section].data;
  const newData =
    payload && currentData.data
      ? currentData.data.filter(d => !payload.contains(d.id))
      : currentData.data;
  return {
    ...currentState,
    searchResults: {
      ...currentState.searchResults,
      [section]: {
        ...currentState.searchResults[section],
        loading,
        error,
        data: {
          ...currentData,
          data: newData,
        },
      },
    },
  };
};

const updatePillSection = ({
  section,
  state: currentState,
  loading = false,
  error = false,
  data,
}) => {
  const sectionName = `pillSection${upperFirst(section)}`;
  return {
    ...currentState,
    searchResults: {
      ...currentState.searchResults,
      [sectionName]: {
        data: data || currentState.searchResults[sectionName].data,
        loading,
        error,
      },
    },
  };
};

const getDefaultPageStateSection = (currentState, section) => {
  return {
    ...currentState,
    pageState: {
      ...currentState.pageState,
      [section]: {
        ...currentState.pageState[section],
        textInputMode: false,
        searchText: '',
      },
    },
  };
};

const addUpdateChosenPills = ({
  section,
  state: currentState,
  chosenItems,
  payload,
  inputMode,
}) => {
  let newPageState = {
    ...currentState,
    pageState: {
      ...currentState.pageState,
      freeTextInputMode: false,
      [section]: {
        ...currentState.pageState[section],
        chosen: addUpdateChosenItem(chosenItems, payload),
        textInputMode: inputMode,
        searchText:
          currentState.pageState[section].searchText ||
          currentState.pageState.freeTextSearch,
      },
    },
  };

  values(INFLUENCERS_HUB_SEARCH_SECTION_NAMES)
    .filter(sectionName => sectionName !== section)
    .forEach(otherSection => {
      newPageState = getDefaultPageStateSection(newPageState, otherSection);
    });

  return newPageState;
};

const removePill = ({ section, state: currentState, id }) => {
  const newChosenItems = removeResetChosenItems(
    currentState.pageState[section].chosen,
    id,
  );

  return {
    ...currentState,
    pageState: {
      ...currentState.pageState,
      [section]: {
        ...currentState.pageState[section],
        chosen: newChosenItems,
        textInputMode: !isEmpty(newChosenItems),
      },
    },
  };
};

const setSearchTextMode = (currentState, mode) => {
  return {
    ...currentState,
    pageState: {
      ...currentState.pageState,
      freeTextInputMode: mode,
    },
  };
};

const setSearchText = (currentState, searchText) => {
  return {
    ...currentState,
    pageState: {
      ...currentState.pageState,
      freeTextSearch: searchText,
    },
  };
};

const startSectionSearchTextMode = (currentState, section) => {
  let newPageState = {
    ...currentState,
    pageState: {
      ...currentState.pageState,
      freeTextInputMode: false,
      [section]: {
        ...currentState.pageState[section],
        textInputMode: true,
        searchText: '',
      },
    },
  };

  values(INFLUENCERS_HUB_SEARCH_SECTION_NAMES)
    .filter(sectionName => sectionName !== section)
    .forEach(otherSection => {
      newPageState = getDefaultPageStateSection(newPageState, otherSection);
    });

  return newPageState;
};

const cancelSectionSearchTextMode = (currentState, section) => {
  return {
    ...currentState,
    pageState: {
      ...currentState.pageState,
      freeTextSearch: '',
      [section]: {
        ...currentState.pageState[section],
        textInputMode: false,
        searchText: '',
      },
    },
  };
};

const setSectionSearchText = (currentState, section, searchText) => {
  return {
    ...currentState,
    pageState: {
      ...currentState.pageState,
      [section]: {
        ...currentState.pageState[section],
        searchText,
      },
    },
  };
};

const setActiveInfluencerPageTab = (currentState, aciveTab) => {
  return {
    ...currentState,
    pageState: {
      ...currentState.pageState,
      activeInfluencerPageTab: aciveTab,
    },
  };
};

const updateSectionSearchCriteria = (currentState, section, criteria) => {
  return {
    ...currentState,
    pageState: {
      ...currentState.pageState,
      searchCriteria: {
        ...currentState.pageState.searchCriteria,
        [section]: criteria,
      },
    },
  };
};

const sortFilterPills = pills => {
  return pills.sort((a, b) => {
    if (a.selected && !b.selected) {
      return -1;
    }

    if (!a.selected && b.selected) {
      return 1;
    }

    return (a.count - b.count) * -1;
  });
};

const calculateTotalResults = (
  influencerCount,
  outletCount,
  socialsCount,
  influencerType,
) => {
  if (isEmpty(influencerType)) {
    return influencerCount + outletCount + socialsCount;
  }

  let totalResults = 0;

  totalResults += some(influencerType, i => {
    return i.searchString.toLowerCase() === 'journalist';
  })
    ? influencerCount
    : 0;
  totalResults += some(influencerType, i => {
    return i.searchString.toLowerCase() === 'outlet';
  })
    ? outletCount
    : 0;
  totalResults += some(influencerType, i => {
    return i.searchString.toLowerCase() === 'social';
  })
    ? socialsCount
    : 0;

  return totalResults;
};

const influencerSearchReducer = (
  state = {
    ...initialState,
  },
  action,
) => {
  switch (action.type) {
    case CLEAR_SEARCH:
      return initialState;
    case PERSIST_APPLIED_FILTERS_ON_PAGE_LEAVE:
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          influencers: {
            data: [],
            loading: true,
            error: false,
          },
          outlets: {
            data: [],
            loading: true,
            error: false,
          },
        },
      };
    case CLEAR_PRIVATE_INFLUENCER_MATCHES:
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          privateInfluencerMatches: {
            data: [],
            loading: false,
            error: false,
            popular: true,
          },
        },
      };

    case GET_SEARCH_RESULTS:
      return {
        ...state,
        pageState: {
          ...state.pageState,
          searchCriteria: action.payload,
        },
        searchResults: {
          ...state.searchResults,
          influencers: {
            data: [],
            loading: true,
            error: false,
          },
          outlets: {
            data: [],
            loading: true,
            error: false,
          },
        },
      };

    case UPDATE_INFLUENCER_TYPE_SEARCH_CRITERIA: {
      const {
        influencerType,
        searchTextCriteria,
        searchInfluencerTypeCriteria,
      } = action.payload;
      const section = influencerType === 'outlet' ? 'outlet' : 'contact';

      return {
        ...state,
        pageState: {
          ...state.pageState,
          searchCriteria: {
            ...state.pageState.searchCriteria,
            [section]: searchTextCriteria,
            influencerType: searchInfluencerTypeCriteria,
          },
        },
      };
    }

    case GET_SEARCH_RESULTS_ERROR:
      return {
        ...state,
        searchResults: {
          ...state.data,
          loading: false,
          error: true,
        },
      };

    case GET_TOPICS:
      return updateSearchResultSubSection({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.topics,
        state,
        loading: true,
      });

    case GET_TOPICS_SUCCESS:
      return updateSearchResultSubSection({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.topics,
        state,
        popular: action.payload.popular,
        data: action.payload.data,
      });

    case GET_TOPICS_ERROR:
      return updateSearchResultSubSection({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.topics,
        state,
        error: true,
      });

    case GET_PILL_SECTION_TOPICS:
      return updatePillSection({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.topics,
        state,
        loading: true,
      });

    case GET_PILL_SECTION_TOPICS_SUCCESS:
      return updatePillSection({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.topics,
        state,
        data: action.payload,
      });

    case GET_PILL_SECTION_TOPICS_ERROR:
      return updatePillSection({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.topics,
        state,
        error: true,
      });

    case GET_PILL_SECTION_FILTER_TOPICS:
      return updatePillSection({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.filterTopics,
        state,
        loading: true,
      });

    case GET_PILL_SECTION_FILTER_TOPICS_SUCCESS:
      return updatePillSection({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.filterTopics,
        state,
        data: action.payload,
      });

    case GET_PILL_SECTION_FILTER_TOPICS_ERROR:
      return updatePillSection({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.filterTopics,
        state,
        error: true,
      });

    case GET_PILL_SECTION_FILTER_LANGUAGE:
      return updatePillSection({
        loading: true,
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.filterLanguages,
        state,
      });

    case GET_PILL_SECTION_FILTER_LANGUAGE_SUCCESS:
      return updatePillSection({
        data: action.payload,
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.filterLanguages,
        state,
      });

    case GET_PILL_SECTION_FILTER_LANGUAGE_ERROR:
      return updatePillSection({
        error: true,
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.filterLanguages,
        state,
      });

    case GET_LOCATIONS:
      return updateSearchResultSubSection({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.locations,
        state,
        loading: true,
      });

    case GET_LOCATIONS_SUCCESS:
      return updateSearchResultSubSection({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.locations,
        state,
        data: action.payload,
      });

    case GET_POPULAR_LOCATIONS_SUCCESS:
      return updateSearchResultSubSection({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.locations,
        state,
        popular: true,
        data: action.payload,
      });

    case GET_LOCATIONS_ERROR:
      return updateSearchResultSubSection({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.locations,
        state,
        error: true,
      });

    case GET_PILL_SECTION_LOCATIONS:
      return updatePillSection({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.locations,
        state,
        loading: true,
      });

    case GET_PILL_SECTION_LOCATIONS_SUCCESS:
      return updatePillSection({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.locations,
        state,
        data: action.payload,
      });

    case GET_PILL_SECTION_LOCATIONS_ERROR:
      return updatePillSection({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.locations,
        state,
        error: true,
      });

    case GET_PILL_SECTION_FILTER_LOCATIONS:
      return updatePillSection({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.filterLocations,
        state,
        loading: true,
      });

    case GET_PILL_SECTION_FILTER_LOCATIONS_SUCCESS:
      return updatePillSection({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.filterLocations,
        state,
        data: action.payload,
      });

    case GET_PILL_SECTION_FILTER_LOCATIONS_ERROR:
      return updatePillSection({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.filterLocations,
        state,
        error: true,
      });

    case GET_OUTLETS:
      return updateSearchResultSubSection({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.outlets,
        state,
        loading: true,
      });

    case GET_OUTLETS_SUCCESS:
      return updateSearchResultSubSection({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.outlets,
        state,
        data: action.payload,
        loading: false,
      });

    case GET_POPULAR_OUTLETS_SUCCESS:
      return updateSearchResultSubSection({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.outlets,
        state,
        popular: true,
        data: action.payload,
      });

    case GET_OUTLETS_ERROR:
      return updateSearchResultSubSection({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.outlets,
        state,
        error: true,
      });

    case RESET_OUTLETS:
      return updateSearchResultSubSection({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.outlets,
        state,
      });

    case RESET_POPULAR_OUTLETS:
      return updateSearchResultSubSection({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.outlets,
        state,
        popular: true,
      });

    case GET_PILL_SECTION_OUTLETS:
      return updatePillSection({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.outlets,
        state,
        loading: true,
      });

    case GET_PILL_SECTION_OUTLETS_SUCCESS:
      return updatePillSection({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.outlets,
        state,
        data: action.payload,
      });

    case GET_PILL_SECTION_OUTLETS_ERROR:
      return updatePillSection({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.outlets,
        state,
        error: true,
      });

    case GET_PILL_SECTION_FILTER_OUTLETS:
      return updatePillSection({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.filterOutlets,
        state,
        loading: true,
      });

    case GET_PILL_SECTION_FILTER_OUTLETS_SUCCESS:
      return updatePillSection({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.filterOutlets,
        state,
        data: action.payload,
      });

    case GET_PILL_SECTION_FILTER_OUTLETS_ERROR:
      return updatePillSection({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.filterOutlets,
        state,
        error: true,
      });

    case GET_PILL_SECTION_FILTER_JOB_ROLES:
      return updatePillSection({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.filterJobRoles,
        state,
        loading: true,
      });

    case GET_PILL_SECTION_FILTER_JOB_ROLES_SUCCESS:
      return updatePillSection({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.filterJobRoles,
        state,
        data: action.payload,
      });

    case GET_PILL_SECTION_FILTER_JOB_ROLES_ERROR:
      return updatePillSection({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.filterJobRoles,
        state,
        error: true,
      });

    case GET_PRIVATE_INFLUENCER_MATCHES:
      return updateSearchResultSubSection({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.privateInfluencerMatches,
        state,
        loading: true,
      });

    case GET_INFLUENCERS: {
      return produce(state, draft => {
        const section =
          draft.searchResults[INFLUENCERS_HUB_SEARCH_SECTION_NAMES.influencers];

        section.loading = true;
        section.error = false;
        section.popular = false;

        section.data = [...(action.append ? section.data : [])];
        section.searchPage = action.searchPage || section.searchPage;
        section.cancelTokenSource = action.payload?.cancelTokenSource;
      });
    }

    case GET_INFLUENCERS_SUCCESS: {
      return produce(state, draft => {
        const section =
          draft.searchResults[INFLUENCERS_HUB_SEARCH_SECTION_NAMES.influencers];

        section.loading = false;
        section.error = false;
        section.popular = false;

        section.data = [...(action.append ? section.data : []), ...action.hits];

        section.pagination = {
          ...section.pagination,
          ...action.pagination,
        };

        section.cancelTokenSource = null;
      });
    }

    case GET_INFLUENCERS_ERROR: {
      return produce(state, draft => {
        const section =
          draft.searchResults[INFLUENCERS_HUB_SEARCH_SECTION_NAMES.influencers];

        section.cancelTokenSource = null;
        section.data = [];
        section.error = true;
        section.loading = false;
      });
    }

    case GET_POPULAR_INFLUENCERS_SUCCESS:
      return updateSearchResultSubSection({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.influencers,
        state,
        popular: true,
        data: action.payload,
      });

    case GET_PRIVATE_INFLUENCER_MATCHES_SUCCESS:
      return updateSearchResultSubSection({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.privateInfluencerMatches,
        state,
        data: action.payload,
      });

    case GET_PRIVATE_INFLUENCER_MATCHES_ERROR:
      return updateSearchResultSubSection({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.privateInfluencerMatches,
        state,
        error: true,
      });

    case REMOVE_INFLUENCERS_FROM_LIST:
      return updateRemoveInfluencerFromList({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.influencers,
        state,
        loading: true,
      });

    case REMOVE_INFLUENCERS_FROM_LIST_SUCCESS:
      return updateRemoveInfluencerFromList({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.influencers,
        state,
        payload: action.payload,
      });

    case REMOVE_INFLUENCERS_FROM_LIST_ERROR:
      return updateRemoveInfluencerFromList({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.influencers,
        state,
        error: true,
      });

    case ADD_UPDATE_CHOSEN_TOPIC:
      return addUpdateChosenPills({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.topics,
        state,
        chosenItems: state.pageState.topics.chosen,
        payload: action.payload,
        inputMode: true,
      });

    case SET_CHOSEN_TOPICS: {
      return addUpdateChosenPills({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.topics,
        state,
        chosenItems: action.payload,
        inputMode: false,
      });
    }

    case SET_CHOSEN_LOCATIONS: {
      return addUpdateChosenPills({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.locations,
        state,
        chosenItems: action.payload,
        inputMode: false,
      });
    }

    case ADD_UPDATE_CHOSEN_LOCATION:
      return addUpdateChosenPills({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.locations,
        state,
        chosenItems: state.pageState.locations.chosen,
        payload: action.payload,
        inputMode: true,
      });

    case ADD_UPDATE_CHOSEN_OUTLET:
      return addUpdateChosenPills({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.outlets,
        state,
        chosenItems: state.pageState.outlets.chosen,
        payload: action.payload,
        inputMode: true,
      });

    case SET_CHOSEN_OUTLETS: {
      return addUpdateChosenPills({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.outlets,
        state,
        chosenItems: action.payload,
        inputMode: false,
      });
    }

    case REMOVE_CHOSEN_TOPIC:
      return removePill({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.topics,
        state,
        id: action.payload.id,
      });

    case REMOVE_CHOSEN_LOCATION:
      return removePill({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.locations,
        state,
        id: action.payload.id,
      });

    case REMOVE_CHOSEN_OUTLET:
      return removePill({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.outlets,
        state,
        id: action.payload.id,
      });

    case UPDATE_SEARCH_TEXT:
      return setSearchText(state, action.payload);

    case START_FREE_SEARCH_TEXT_MODE:
      return setSearchTextMode(state, true);

    case CANCEL_FREE_SEARCH_TEXT_MODE:
      return setSearchTextMode(state, false);

    case SET_ACTIVE_INFLUENCER_PAGE_TAB:
      return setActiveInfluencerPageTab(state, action.payload);

    case CLEAR_ACTIVE_INFLUENCER_TAB_ON_PAGE_LEAVE:
      return {
        ...state,
        pageState: {
          ...state.pageState,
          activeInfluencerPageTab: '',
        },
      };

    case CANCEL_TOPIC_SEARCH_TEXT_MODE:
      return cancelSectionSearchTextMode(
        state,
        INFLUENCERS_HUB_SEARCH_SECTION_NAMES.topics,
      );

    case START_TOPIC_SEARCH_TEXT_MODE:
      return startSectionSearchTextMode(
        state,
        INFLUENCERS_HUB_SEARCH_SECTION_NAMES.topics,
      );

    case CANCEL_LOCATION_SEARCH_TEXT_MODE:
      return cancelSectionSearchTextMode(
        state,
        INFLUENCERS_HUB_SEARCH_SECTION_NAMES.locations,
      );

    case START_LOCATION_SEARCH_TEXT_MODE:
      return startSectionSearchTextMode(
        state,
        INFLUENCERS_HUB_SEARCH_SECTION_NAMES.locations,
      );

    case CANCEL_OUTLET_SEARCH_TEXT_MODE:
      return cancelSectionSearchTextMode(
        state,
        INFLUENCERS_HUB_SEARCH_SECTION_NAMES.outlets,
      );

    case START_OUTLET_SEARCH_TEXT_MODE:
      return startSectionSearchTextMode(
        state,
        INFLUENCERS_HUB_SEARCH_SECTION_NAMES.outlets,
      );

    case UPDATE_TOPIC_SEARCH_TEXT:
      return setSectionSearchText(
        state,
        INFLUENCERS_HUB_SEARCH_SECTION_NAMES.topics,
        action.payload,
      );

    case UPDATE_SEARCH_BY_CONTACT_NAME:
      return updateSectionSearchCriteria(
        state,
        INFLUENCERS_HUB_SEARCH_CRITERIA_NAMES[action.payload.section],
        action.payload.criteria,
      );

    case UPDATE_LOCATION_SEARCH_TEXT:
      return setSectionSearchText(
        state,
        INFLUENCERS_HUB_SEARCH_SECTION_NAMES.locations,
        action.payload,
      );

    case UPDATE_OUTLET_SEARCH_TEXT:
      return setSectionSearchText(
        state,
        INFLUENCERS_HUB_SEARCH_SECTION_NAMES.outlets,
        action.payload,
      );

    case UPDATE_TOPIC_SEARCH_CRITERIA:
      return updateSectionSearchCriteria(
        state,
        INFLUENCERS_HUB_SEARCH_CRITERIA_NAMES[action.payload.section],
        action.payload.criteria,
      );

    case UPDATE_LOCATION_SEARCH_CRITERIA:
      return updateSectionSearchCriteria(
        state,
        INFLUENCERS_HUB_SEARCH_CRITERIA_NAMES.location,
        action.payload,
      );

    case UPDATE_OUTLET_SEARCH_CRITERIA:
      return updateSectionSearchCriteria(
        state,
        INFLUENCERS_HUB_SEARCH_CRITERIA_NAMES.outlet,
        action.payload,
      );

    case SET_CONTACT_ATTRIBUTES_IDS:
      return {
        ...state,
        pageState: {
          ...state.pageState,
          contactAttributesIds: action.payload,
        },
      };

    case ADD_SUBSCRIBED_FILTER:
      return {
        ...state,
        pageState: {
          ...state.pageState,
          searchCriteria: {
            ...state.pageState.searchCriteria,
            unsubscribed: action.payload,
          },
        },
      };

    case REMOVE_SUBSCRIBED_FILTER:
      return {
        ...state,
        pageState: {
          ...state.pageState,
          searchCriteria: {
            ...state.pageState.searchCriteria,
            unsubscribed: [],
          },
        },
      };

    case ADD_ADVANCED_SEARCH_CRITERIA: {
      const { referenceType } = action.payload[0];
      const filterPropertyName = IHUB_ADVANCED_SEARCH_TYPES[referenceType]
        ? IHUB_ADVANCED_SEARCH_TYPES[referenceType].searchCriteriaPropertyName
        : referenceType;

      const newFilters = unionWith(
        state.pageState.searchCriteria[filterPropertyName],
        map(action.payload, ({ id, description }) =>
          getCriteria({
            searchId: id,
            searchString: description,
          }),
        ),
        isEqual,
      );

      return updateSectionSearchCriteria(state, filterPropertyName, newFilters);
    }

    case REMOVE_ADVANCED_SEARCH_CRITERIA: {
      const { referenceType } = action.payload[0];
      const filterPropertyName = IHUB_ADVANCED_SEARCH_TYPES[referenceType]
        ? IHUB_ADVANCED_SEARCH_TYPES[referenceType].searchCriteriaPropertyName
        : referenceType;
      const filterIds = map(action.payload, 'id');

      const newFilters = reject(
        state.pageState.searchCriteria[filterPropertyName],
        criteria => includes(filterIds, criteria.searchId),
      );

      return updateSectionSearchCriteria(state, filterPropertyName, newFilters);
    }

    case CLEAR_ADVANCED_SEARCH_CRITERIA: {
      const filterPropertyName =
        IHUB_ADVANCED_SEARCH_TYPES[action.payload].searchCriteriaPropertyName;

      return updateSectionSearchCriteria(state, filterPropertyName, []);
    }

    case UPDATE_SEARCH_RESULTS_TOTAL_HITS: {
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          ...action.payload,
        },
      };
    }

    case CHANGE_PAGE_NUMBER:
      return {
        ...state,
        pageState: {
          ...state.pageState,
          searchCriteria: {
            ...state.pageState.searchCriteria,
            pagenumber: action.payload.pageNumber,
          },
        },
      };

    case SET_SORT_FIELD:
      return {
        ...state,
        pageState: {
          ...state.pageState,
          searchCriteria: {
            ...state.pageState.searchCriteria,
            sort: action.payload,
          },
        },
      };

    case SET_SORT_DIRECTION:
      return {
        ...state,
        pageState: {
          ...state.pageState,
          searchCriteria: {
            ...state.pageState.searchCriteria,
            sortOrder: action.payload,
          },
        },
      };

    case SET_TOPIC_FILTER: {
      const currentFilters = first(
        state.searchResults.pillSectionFilterTopics.data,
      );
      const existingItemIndex = findIndex(currentFilters.data, {
        id: action.payload,
      });

      if (existingItemIndex !== -1) {
        currentFilters.data[existingItemIndex].selected = !currentFilters.data[
          existingItemIndex
        ].selected;
      }

      currentFilters.data = sortFilterPills(currentFilters.data);

      return updatePillSection({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.filterTopics,
        state,
        data: [currentFilters],
      });
    }

    case CLEAR_TOPIC_FILTER:
      return updatePillSection({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.filterTopics,
        state,
        data: [],
      });

    case SET_LOCATION_FILTER: {
      const currentFilters = first(
        state.searchResults.pillSectionFilterLocations.data,
      );
      const existingItemIndex = findIndex(currentFilters.data, {
        id: action.payload,
      });

      if (existingItemIndex !== -1) {
        currentFilters.data[existingItemIndex].selected = !currentFilters.data[
          existingItemIndex
        ].selected;
      }

      currentFilters.data = sortFilterPills(currentFilters.data);

      return updatePillSection({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.filterLocations,
        state,
        data: [currentFilters],
      });
    }

    case CLEAR_LOCATION_FILTER:
      return updatePillSection({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.filterLocations,
        state,
        data: [],
      });

    case SET_OUTLET_FILTER: {
      const currentFilters = first(
        state.searchResults.pillSectionFilterOutlets.data,
      );
      const existingItemIndex = findIndex(currentFilters.data, {
        id: action.payload,
      });

      if (existingItemIndex !== -1) {
        currentFilters.data[existingItemIndex].selected = !currentFilters.data[
          existingItemIndex
        ].selected;
      }

      currentFilters.data = sortFilterPills(currentFilters.data);

      return updatePillSection({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.filterOutlets,
        state,
        data: [currentFilters],
      });
    }

    case CLEAR_OUTLET_FILTER:
      return updatePillSection({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.filterOutlets,
        state,
        data: [],
      });

    case SET_JOB_ROLE_FILTER: {
      const currentFilters = first(
        state.searchResults.pillSectionFilterJobRoles.data,
      );
      const existingItemIndex = findIndex(currentFilters.data, {
        id: action.payload,
      });

      if (existingItemIndex !== -1) {
        currentFilters.data[existingItemIndex].selected = !currentFilters.data[
          existingItemIndex
        ].selected;
      }

      currentFilters.data = sortFilterPills(currentFilters.data);

      return updatePillSection({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.filterJobRoles,
        state,
        data: [currentFilters],
      });
    }

    case SET_LANGUAGE_FILTER: {
      return updatePillSection({
        data: [action.payload],
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.filterLanguages,
        state,
      });
    }

    case CLEAR_LANGUAGE_FILTER:
      return updatePillSection({
        data: [],
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.filterLanguages,
        state,
      });

    case SET_JOB_ROLE_FILTER_SIMPLE: {
      const currentFilters = {
        data: [
          {
            ...action.payload,
            selected: true,
          },
        ],
      };
      currentFilters.data = sortFilterPills(currentFilters.data);

      return updatePillSection({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.filterJobRoles,
        state,
        data: [currentFilters],
      });
    }

    case CLEAR_JOB_ROLE_FILTER:
      return updatePillSection({
        section: INFLUENCERS_HUB_SEARCH_SECTION_NAMES.filterJobRoles,
        state,
        data: [],
      });

    case UPDATE_REACH_AND_IMPACT_FILTER: {
      const { name, range } = action.payload;

      return {
        ...state,
        pageState: {
          ...state.pageState,
          searchCriteria: {
            ...state.pageState.searchCriteria,
            [name]: isEmpty(range) ? null : range,
          },
        },
      };
    }

    case CLEAR_REACH_AND_IMPACT_FILTER: {
      return {
        ...state,
        pageState: {
          ...state.pageState,
          searchCriteria: {
            ...state.pageState.searchCriteria,
            articleReach: null,
            socialReach: null,
          },
        },
      };
    }

    case SET_SEARCH_AGGREGATIONS: {
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          aggregations: action.payload,
        },
      };
    }

    case SET_SELECTED_LIST_ID:
      return {
        ...state,
        pageState: {
          ...state.pageState,
          selectedListId: action.payload,
        },
      };

    case RESET_SELECTED_LIST_ID:
      return {
        ...state,
        pageState: {
          ...state.pageState,
          selectedListId: null,
        },
      };

    case UPDATE_SEARCH_BY_OUTLET_NAME:
      return updateSectionSearchCriteria(
        state,
        INFLUENCERS_HUB_SEARCH_CRITERIA_NAMES[action.payload.section],
        action.payload.criteria,
      );

    default:
      return state;
  }
};

const canUseSuggestionsFECaching = getCanUseSuggestionsFECaching();

const populateCriteriaArray = (selectedPills, pillFilter = {}) => {
  const criteria = filter(selectedPills, pillFilter).map((pill, index) => {
    const pillId = pill.rdsIds || pill.id;

    const isLocationPill = Object.values(
      FILTER_SECTION_CONFIG.LOCATION.PILL_TYPES,
    ).includes(pill?.type);
    const omitSearchId =
      `${pill.id}`.includes('keyword-') ||
      `${pill.id}`.includes('talking-about-');
    let searchString = `${pill.name.replace(/"/g, '')}`;
    let locationType = '';

    if (isLocationPill) {
      searchString = pill.searchString;
      locationType = `${pill.type}`;
    }
    return getCriteria({
      locationType,
      searchString,
      searchId: omitSearchId ? '' : `${pillId}`,
      booleanCondition: pill.booleanCondition,
      criteriaOrder: index + 1,
    });
  });
  return criteria;
};

const fetchTopicSuggestionsFromApi = async (searchText, state) => {
  const urlParams =
    searchText && searchText.length > 2
      ? `?keyword=${encodeURIComponent(searchText)}`
      : '';

  const localCacheTtl = 10 * 60;

  const {
    data: { data: topics },
  } = await performGet(
    `${INFLUENCER_HUB_ENDPOINT}/suggestions/topics${urlParams}`,
    undefined,
    undefined,
    undefined,
    canUseSuggestionsFECaching,
    false,
    localCacheTtl,
  );

  if (!topics) {
    throw new Error('Failed to fetch topic suggestions.');
  }

  return topics;
};

const getKeywordPill = searchText => ({
  id: `keyword-${searchText}`,
  name: `"${searchText}"`,
  type: FILTER_SECTION_CONFIG.TOPIC.PILL_TYPES.keyword,
  typeKey: FILTER_SECTION_CONFIG.TOPIC.PILL_TYPE_I18N_KEYS.keyword,
});

const getTalkingAboutPill = searchText => ({
  id: `talking-about-${searchText}`,
  name: `"${searchText}"`,
  type: FILTER_SECTION_CONFIG.TOPIC.PILL_TYPES.talkingAbout,
  typeKey: FILTER_SECTION_CONFIG.TOPIC.PILL_TYPE_I18N_KEYS.talkingAbout,
});

const getTopicsPills = topics =>
  topics.map(topic => ({
    ...topic,
    type: FILTER_SECTION_CONFIG.TOPIC.PILL_TYPES.topic,
    typeKey: FILTER_SECTION_CONFIG.TOPIC.PILL_TYPE_I18N_KEYS.topic,
  }));

const getTopicsGroupWithoutTitle = ({ topics }) => ({
  data: getTopicsPills(topics),
});

const getTopicsSectionGroup = ({ intl, messages, topics }) => ({
  ...getTopicsGroupWithoutTitle({ topics }),
  title: intl.formatMessage(messages.topics),
  titleI18nKey: 'topics',
});

const getKeywordsAndTalkingAboutGroup = ({ intl, messages, searchText }) => {
  const hasConnectBasicFF = userHasFeatureFlag(FEATURES.connectBasic);

  return {
    title: intl.formatMessage(messages.customKeyword),
    titleI18nKey: 'customKeyword',
    data: [
      getKeywordPill(searchText),
      !hasConnectBasicFF && getTalkingAboutPill(searchText),
    ].filter(Boolean),
  };
};

export const clearPrivateInfluencerMatches = () => ({
  type: CLEAR_PRIVATE_INFLUENCER_MATCHES,
});

export const resetSelectedListId = () => ({
  type: RESET_SELECTED_LIST_ID,
});

export const resetToPageOne = (
  savePreviousSelectedInfluencers = false,
) => dispatch => {
  if (savePreviousSelectedInfluencers) {
    dispatch({
      type: INFLUENCERS_ON_SEARCH,
    });
  } else {
    dispatch({
      type: INFLUENCERS_BULK_UNSELECT_ALL_INFLUENCERS,
    });
  }
  dispatch({
    type: CHANGE_PAGE_NUMBER,
    payload: { pageNumber: 1 },
  });
};

export const syncTopicSearchCriteria = () => async (dispatch, getState) => {
  // Take the current state of the search fitlers
  const state = getState();
  const selectedTopics = state.influencerSearch.pageState.topics.chosen;

  // Filter out all non "Talking About" topics
  const topicsCriteria = populateCriteriaArray(
    selectedTopics,
    pill => pill.type !== FILTER_SECTION_CONFIG.TOPIC.PILL_TYPES.talkingAbout,
  );

  // Filter only "Talking About" topics
  const talkingAboutCriteria = populateCriteriaArray(selectedTopics, {
    type: FILTER_SECTION_CONFIG.TOPIC.PILL_TYPES.talkingAbout,
  });

  // Update topic search criteria (currently, Topic and Keywords)
  dispatch({
    type: UPDATE_TOPIC_SEARCH_CRITERIA,
    payload: {
      criteria: topicsCriteria,
      section: INFLUENCERS_HUB_SEARCH_CRITERIA_NAMES.topic,
    },
  });

  // Update "Talking About" search criteria
  dispatch({
    type: UPDATE_TOPIC_SEARCH_CRITERIA,
    payload: {
      criteria: talkingAboutCriteria,
      section: INFLUENCERS_HUB_SEARCH_CRITERIA_NAMES.talkingAbout,
    },
  });
};

export const getPillFromAggBucket = aggBucket => ({
  count: aggBucket.count,
  id: aggBucket.key,
  isLocationFilter: aggBucket.isLocationFilter,
  name: aggBucket.name || '',
  outletId: null,
  selected: false,
  type: aggBucket.type ?? null,
  userRelevance: '1',
});

export const setAggregationFilters = (
  newFilters,
  existingFilters = [],
  isLocationFilter = false,
) => {
  const mappedFilters = newFilters.map(item => {
    if (!item.name) {
      // eslint-disable-next-line
      console.warn(
        `No aggregation bucket name for isLocationFilter: ${isLocationFilter} type: ${item.type} key: ${item.key}`,
      );
    }

    return getPillFromAggBucket({ ...item, isLocationFilter });
  });

  const selectedFilters = map(
    filter(get(existingFilters, '[0].data'), {
      selected: true,
    }),
    selectedFilter => ({
      ...selectedFilter,
      count: get(
        find(mappedFilters, {
          id: selectedFilter.id,
        }),
        'count',
        0,
      ),
    }),
  );

  const data = [
    ...selectedFilters,
    ...differenceBy(mappedFilters, selectedFilters, 'id'),
  ];

  return [
    {
      title: 'FILTERS',
      data,
    },
  ];
};

export const setSearchCriteria = searchCriteria => ({
  type: GET_SEARCH_RESULTS,
  payload: searchCriteria,
});

const dispatchPillSections = (noUpdate = [], dispatch) => {
  if (noUpdate.includes(INFLUENCERS_HUB_SEARCH_CRITERIA_NAMES.all)) {
    return;
  }

  if (!noUpdate.includes(INFLUENCERS_HUB_SEARCH_CRITERIA_NAMES.topic)) {
    dispatch({
      type: GET_PILL_SECTION_FILTER_TOPICS,
    });
  }

  if (!noUpdate.includes(INFLUENCERS_HUB_SEARCH_CRITERIA_NAMES.language)) {
    dispatch({
      type: GET_PILL_SECTION_FILTER_LANGUAGE,
    });
  }

  if (!noUpdate.includes(INFLUENCERS_HUB_SEARCH_CRITERIA_NAMES.location)) {
    dispatch({
      type: GET_PILL_SECTION_FILTER_LOCATIONS,
    });
  }

  if (!noUpdate.includes(INFLUENCERS_HUB_SEARCH_CRITERIA_NAMES.outlet)) {
    dispatch({
      type: GET_PILL_SECTION_FILTER_OUTLETS,
    });
  }

  if (!noUpdate.includes(INFLUENCERS_HUB_SEARCH_CRITERIA_NAMES.jobRole)) {
    dispatch({
      type: GET_PILL_SECTION_FILTER_JOB_ROLES,
    });
  }
};

const setLanguageAggregations = ({ dispatch, languageFilters, languages }) => {
  try {
    dispatch({
      type: GET_PILL_SECTION_FILTER_LANGUAGE_SUCCESS,
      payload: setAggregationFilters(languages, languageFilters),
    });
  } catch (error) {
    dispatch({
      type: GET_PILL_SECTION_FILTER_LANGUAGE_ERROR,
      payload: error.message,
    });
  }
};

export const dispatchPillSectionsSuccess = (
  searchEndpoint,
  noUpdate = [],
) => async (dispatch, getState) => {
  if (noUpdate.includes(INFLUENCERS_HUB_SEARCH_CRITERIA_NAMES.all)) {
    return;
  }

  const state = getState();
  const existingFilters = state.influencerSearch.searchResults;

  let aggs = {};

  try {
    const searchCriteria = {
      ...fullSearchCriteriaSelector(state),
      pagesize: 0,
      aggsize: 50,
      aggsInclude: ['ALL'],
    };

    // get aggregations
    const response = await performPost(searchEndpoint, searchCriteria);
    if (response.data.aggs) {
      aggs = response.data.aggs;
    }
  } catch (e) {
    dispatch({
      type: SET_SEARCH_AGGREGATIONS,
      payload: {},
    });
  }

  dispatch({
    type: SET_SEARCH_AGGREGATIONS,
    payload: aggs,
  });

  if (!noUpdate.includes(INFLUENCERS_HUB_SEARCH_CRITERIA_NAMES.language)) {
    const languages = aggs?.languages?.buckets ?? [];
    const languageFilters =
      existingFilters?.pillSectionFilterLanguages?.data ?? [];

    setLanguageAggregations({
      dispatch,
      languageFilters,
      languages,
    });
  }

  if (!noUpdate.includes(INFLUENCERS_HUB_SEARCH_CRITERIA_NAMES.topic)) {
    try {
      const topics = aggs?.topics?.buckets ?? [];

      dispatch({
        type: GET_PILL_SECTION_FILTER_TOPICS_SUCCESS,
        payload: setAggregationFilters(
          topics,
          existingFilters.pillSectionFilterTopics.data,
        ),
      });
    } catch (e) {
      dispatch({
        type: GET_PILL_SECTION_FILTER_TOPICS_ERROR,
        payload: e.message,
      });
      dispatch({
        type: GET_PILL_SECTION_FILTER_LOCATIONS_ERROR,
        payload: e.message,
      });
      dispatch({
        type: GET_PILL_SECTION_FILTER_OUTLETS_ERROR,
        payload: e.message,
      });
      dispatch({
        type: GET_PILL_SECTION_FILTER_JOB_ROLES_ERROR,
        payload: e.message,
      });
    }
  }

  if (!noUpdate.includes(INFLUENCERS_HUB_SEARCH_CRITERIA_NAMES.location)) {
    const locationAggregationKeys = Object.values(
      FILTER_SECTION_CONFIG.LOCATION.BUCKET_KEYS,
    );

    const allLocationAggs = locationAggregationKeys.map(key => {
      const agg = aggs[key];
      return (
        agg?.buckets?.map(bucket => ({
          ...bucket,
          type: FILTER_SECTION_CONFIG.LOCATION.PILL_TYPES_BY_BUCKET_KEY[key],
        })) ?? []
      );
    });

    // Flatten the array of arrays
    const allLocationBuckets = allLocationAggs.reduce(
      (result, agg) => [...result, ...agg],
      [],
    );

    dispatch({
      type: GET_PILL_SECTION_FILTER_LOCATIONS_SUCCESS,
      payload: setAggregationFilters(
        allLocationBuckets,
        existingFilters.pillSectionFilterLocations.data,
        true,
      ),
    });
  }

  if (!noUpdate.includes(INFLUENCERS_HUB_SEARCH_CRITERIA_NAMES.outlet)) {
    dispatch({
      type: GET_PILL_SECTION_FILTER_OUTLETS_SUCCESS,
      payload: setAggregationFilters(
        aggs?.contactOutletNames?.buckets ?? [],
        existingFilters.pillSectionFilterOutlets.data,
      ),
    });
  }

  if (!noUpdate.includes(INFLUENCERS_HUB_SEARCH_CRITERIA_NAMES.jobRole)) {
    dispatch({
      type: GET_PILL_SECTION_FILTER_JOB_ROLES_SUCCESS,
      payload: setAggregationFilters(
        aggs?.jobTitles?.buckets ?? [],
        existingFilters.pillSectionFilterJobRoles.data,
      ),
    });
  }
};

export const persistAppliedFiltersOnPageLeave = () => dispatch =>
  dispatch({ type: PERSIST_APPLIED_FILTERS_ON_PAGE_LEAVE });

export const setActiveInfluencerTabDispatcher = activeTab => dispatch => {
  dispatch({ type: SET_ACTIVE_INFLUENCER_PAGE_TAB, payload: activeTab });
};

export const clearActiveInfluencerTabOnPageLeave = () => dispatch =>
  dispatch({ type: CLEAR_ACTIVE_INFLUENCER_TAB_ON_PAGE_LEAVE });

export const getSearchResults = (reloadPills = true, noUpdate = []) => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const fullSearchCriteria = fullSearchCriteriaSelector(state);
  const filtersCount = countFilters(fullSearchCriteria);
  const selectedListId = state.influencerSearch.pageState.selectedListId;
  const searchCriteriaWithOmittedLanguage = omit(
    fullSearchCriteria,
    INFLUENCERS_HUB_SEARCH_CRITERIA_NAMES.language,
  );
  const activeInfluencerPageTab =
    state.influencerSearch.pageState.activeInfluencerPageTab;
  const CONTRIBUTORS = 'CONTRIBUTORS';
  // Do not execute a new search if no filter is selected in search result page
  if (
    activeInfluencerPageTab === CONTRIBUTORS ||
    isSearchDisabledSelector(state) ||
    (filtersCount === 0 && !selectedListId)
  ) {
    return;
  }

  const dispatchedSearchPage =
    selectedListId === 'private'
      ? 'private'
      : selectedListId === 'unsubscribed'
      ? 'unsubscribed'
      : selectedListId
      ? 'savedList'
      : 'search';

  dispatch({ type: GET_INFLUENCERS, searchPage: dispatchedSearchPage });

  if (reloadPills) {
    if (!noUpdate.includes(INFLUENCERS_HUB_SEARCH_INFO_TYPE.aggregations)) {
      dispatchPillSections(noUpdate, dispatch);
    }
  }

  const endpoints = {
    private: INFLUENCER_GET_PRIVATE_INFLUENCERS_URL,
    savedList: INFLUENCER_SAVED_LISTS_LIST_MEMBERS_ENDPOINT.replace(
      '<listId>',
      selectedListId,
    ),
    unsubscribed: INFLUENCER_GET_UNSUBSCRIBED_INFLUENCERS_URL,
    search: `${INFLUENCER_HUB_ENDPOINT}/search`,
  };

  const searchEndpoint = endpoints[dispatchedSearchPage];
  const PRIVATE = 'private';
  const UNSUBSCRIBED = 'unsubscribed';
  const searchCriteria =
    dispatchedSearchPage === (PRIVATE || UNSUBSCRIBED)
      ? searchCriteriaWithOmittedLanguage
      : fullSearchCriteria;

  try {
    const { data } = await performPost(searchEndpoint, searchCriteria);

    const {
      currentpagenumber: currentPageNumber,
      pagesize: pageSize,
      totalhits: totalHits,
      totalJournalists,
      totalOutlets,
      totalpages: totalPages,
      totalSocials,
    } = data;

    const currentSearchPage = getSearchPageSelector(
      INFLUENCERS_HUB_SEARCH_SECTION_NAMES.influencers,
    )(getState());

    if (currentSearchPage !== dispatchedSearchPage) return;

    dispatch({
      type: GET_INFLUENCERS_SUCCESS,
      hits: data.data || [],
      pagination: {
        currentPageNumber,
        pageSize,
        totalHits,
        totalPages,
      },
    });

    const influencerType =
      state.influencerSearch.pageState.searchCriteria.influencerType;

    let searchResultPayload = {
      totalResults: isEmpty(influencerType)
        ? totalHits
        : calculateTotalResults(
            totalJournalists,
            totalOutlets,
            totalSocials,
            influencerType,
          ),
      currentPageNumber,
      totalPages,
    };

    if (
      !noUpdate.includes(INFLUENCERS_HUB_SEARCH_CRITERIA_NAMES.influencerType)
    ) {
      searchResultPayload = {
        ...searchResultPayload,
        totalOutlets,
        totalJournalists,
        totalSocials,
      };
    }

    dispatch({
      type: UPDATE_SEARCH_RESULTS_TOTAL_HITS,
      payload: searchResultPayload,
    });

    // Get aggregations for pills
    if (reloadPills) {
      if (!noUpdate.includes(INFLUENCERS_HUB_SEARCH_INFO_TYPE.aggregations)) {
        dispatch(dispatchPillSectionsSuccess(searchEndpoint, noUpdate));
      }
    }

    return data.data;
  } catch (e) {
    dispatch({
      type: GET_INFLUENCERS_ERROR,
      payload: e.message,
    });

    dispatch({
      type: GET_PILL_SECTION_FILTER_TOPICS_ERROR,
      payload: e.message,
    });
  }
};

export const getSearchResultsPreview = () => async (dispatch, getState) => {
  const state = getState();

  if (isSearchDisabledSelector(state)) {
    return;
  }

  const cancelTokenSource = getCancelTokenSource();
  const influencerCriteria = influencerCriteriaSelector(state);
  const isOutletOnlySearch = isOutletOnlySearchSelector(state);
  const isInfluencerOnlySearch = isInfluencerOnlySearchSelector(state);

  const hasOutletCriteria =
    state.influencerSearch.pageState.outlets.chosen.length > 0;
  const hasFreeTextSearch =
    state.influencerSearch.pageState.freeTextSearch.length > 0;
  const hasLocationCriteria =
    state.influencerSearch.pageState.locations.chosen.length > 0;
  const hasTopicCriteria =
    state.influencerSearch.pageState.topics.chosen.length > 0;
  const hasChosenCriteria =
    hasOutletCriteria || hasLocationCriteria || hasTopicCriteria;

  const keyword =
    hasFreeTextSearch && !hasChosenCriteria
      ? [
          getCriteria({
            searchString: state.influencerSearch.pageState.freeTextSearch,
          }),
        ]
      : [];

  if (!isOutletOnlySearch) {
    dispatch({
      type: GET_INFLUENCERS,
      payload: {
        cancelTokenSource,
      },
    });
  }

  if (!isInfluencerOnlySearch) {
    dispatch({
      type: GET_OUTLETS,
    });
  }

  try {
    let influencerData = [];
    let influencerResult = {};

    if (!isOutletOnlySearch) {
      const influencerTypeCriteria = {
        ...state.influencerSearch.pageState.searchCriteria,
        pagesize: 16,
        influencerType: getSearchCriteriaForInfluencerType(
          getInfluencerTypeByCriteria(influencerCriteria),
        ),
        keyword,
      };

      influencerResult = await request({
        url: `${INFLUENCER_HUB_ENDPOINT}/search`,
        data: influencerTypeCriteria,
        method: 'POST',
        config: {
          cancelToken: cancelTokenSource.token,
        },
      });

      influencerData = influencerResult.data.data.map(influencerPreviewToData);
    }

    let outletData = [];
    let outletResult = {};
    let totalOutletResults = 0;
    let totalOutletPages = 0;
    let totalOutletJournalist = 0;

    // get preview results for influential outlets
    if (!isInfluencerOnlySearch) {
      const outletInfluencerTypeCriteria = {
        ...state.influencerSearch.pageState.searchCriteria,
        pagesize: 16,
        influencerType: getSearchCriteriaForInfluencerType('outlet'),
        keyword,
      };

      outletResult = await performPost(
        `${INFLUENCER_HUB_ENDPOINT}/search`,
        outletInfluencerTypeCriteria,
      );

      totalOutletResults = outletResult.data.totalhits;
      totalOutletPages = outletResult.data.totalpages;
      totalOutletJournalist = outletResult.data.totalJournalists;

      if (!hasOutletCriteria) {
        outletData = outletResult?.data?.data?.map(outletPreviewToData) ?? [];
      }
    }

    const influencersState = getState().influencerSearch.searchResults
      .influencers;

    /*
      TODO: EVER-4853 Figure out a better way to cancel prior requests
      This creates a race condition
      1. start search 1 - resets `hits` data
      2. start search 2 - resets `hits` data
      3. resolve search 1 - replaces `hits` with results
      4. resolve search 2 - is canceled because results already exist.
      ... but if search 1 resolved second, the UI will show the wrong thing.
    */
    if (isEmpty(influencersState.data)) {
      const {
        currentpagenumber: currentPageNumber = 0,
        pagesize: pageSize = 16,
        totalhits: totalHits = 0,
        totalpages: totalPages = 1,
      } = influencerResult?.data;
      dispatch({
        type: GET_INFLUENCERS_SUCCESS,
        hits: influencerData,
        pagination: {
          currentPageNumber,
          pageSize,
          totalHits,
          totalPages,
        },
      });
    } else {
      dispatch({
        type: GET_INFLUENCERS_CANCELED,
      });
    }

    dispatch({
      type: GET_OUTLETS_SUCCESS,
      payload: outletData,
    });

    dispatch({
      type: UPDATE_SEARCH_RESULTS_TOTAL_HITS,
      payload: {
        currentPageNumber: influencerResult.data.currentpagenumber ?? 0,
        totalPages: influencerResult.data.totalpages + totalOutletPages ?? 0,
        totalResults: calculateTotalResults(
          influencerResult.data.totalJournalists,
          totalOutletResults,
          influencerResult.data.totalSocials,
          state.influencerSearch.pageState.searchCriteria.influencerType,
        ),
        totalOutlets: totalOutletResults,
        totalJournalists:
          influencerResult.data.totalJournalists + totalOutletJournalist,
        totalSocials: influencerResult.data.totalSocials,
      },
    });
  } catch (e) {
    if (!isRequestCancel(e)) {
      dispatch({
        type: GET_INFLUENCERS_ERROR,
        payload: e.message,
      });
      dispatch({
        type: GET_OUTLETS_ERROR,
        payload: e.message,
      });
    }
  }
};

const resetPageNumberAndGetNewSearchResults = () => dispatch => {
  dispatch(resetToPageOne());
  dispatch(getSearchResults());
};

export const getTopics = (searchText = '') => async (dispatch, getState) => {
  const searchTextLength = searchText.length;
  const shouldOnlyUsePopularTopics = searchTextLength <= 2;
  const state = getState();
  const hasConnectBasicFF = userHasFeatureFlag(FEATURES.connectBasic);
  const topics = state.influencerSearch.searchResults.topics;
  const popularTopicsAreAlreadyLoaded =
    topics.popular && topics.data.length > 0;

  if (shouldOnlyUsePopularTopics && popularTopicsAreAlreadyLoaded) {
    return;
  }

  dispatch({
    type: GET_TOPICS,
  });

  try {
    const topics = await fetchTopicSuggestionsFromApi(searchText, state);
    let topicsPayload;
    let popular = true;

    // Add the searchText the user typed in as a custom keyword
    if (searchText.length > 2) {
      topicsPayload = [
        getKeywordPill(searchText),
        !hasConnectBasicFF && getTalkingAboutPill(searchText),
        ...getTopicsPills(topics),
      ].filter(Boolean);

      popular = false;
    } else {
      topicsPayload = getTopicsPills(topics);
    }

    dispatch({
      type: GET_TOPICS_SUCCESS,
      payload: {
        data: topicsPayload || [],
        popular,
      },
    });
  } catch (e) {
    dispatch({
      type: GET_TOPICS_ERROR,
      payload: e.message,
    });
  }
};

export const getPillSectionTopics = (
  searchText = '',
  { showGrouping = true, intl, messages } = {},
) => async (dispatch, getState) => {
  const state = getState();
  if (searchText.length <= 2) {
    dispatch({
      type: GET_PILL_SECTION_TOPICS_SUCCESS,
      payload: [],
    });
  } else {
    dispatch({
      type: GET_PILL_SECTION_TOPICS,
    });

    try {
      const topics = await fetchTopicSuggestionsFromApi(searchText, state);
      const topicsSearchText = topicsSearchTextSelector(state);

      if (!topicsSearchText) {
        return;
      }
      const groupedResult = showGrouping
        ? [
            getKeywordsAndTalkingAboutGroup({ intl, messages, searchText }),
            getTopicsSectionGroup({ intl, messages, topics }),
          ]
        : [getTopicsGroupWithoutTitle({ topics })];

      dispatch({
        type: GET_PILL_SECTION_TOPICS_SUCCESS,
        payload: groupedResult,
      });
    } catch (e) {
      dispatch({
        type: GET_PILL_SECTION_TOPICS_ERROR,
        payload: e.message,
      });
    }
  }
};

export const getPillSectionKeywordsOnly = (
  searchText = '',
  { intl, messages } = {},
) => dispatch => {
  const payload = [];

  dispatch({
    type: GET_PILL_SECTION_TOPICS,
  });

  if (searchText.length > 2) {
    payload.push(
      getKeywordsAndTalkingAboutGroup({ intl, messages, searchText }),
    );
  }

  dispatch({
    type: GET_PILL_SECTION_TOPICS_SUCCESS,
    payload,
  });
};

export const topicToPill = topic => ({
  ...topic,
  type: FILTER_SECTION_CONFIG.TOPIC.PILL_TYPES.topic,
  typeKey: FILTER_SECTION_CONFIG.TOPIC.PILL_TYPE_I18N_KEYS.topic,
});

const canUseInfluencerDisplayTargetArea = getCanUseInfluencerDisplayTargetArea();

const getLocationsPills = locations => {
  const locationsPills = locations.map(location => {
    if (location.type === 'Country') {
      return {
        ...location,
        typeKey: FILTER_SECTION_CONFIG.TOPIC.PILL_TYPE_I18N_KEYS.country,
      };
    } else if (location.type === 'City') {
      return {
        ...location,
        typeKey: FILTER_SECTION_CONFIG.TOPIC.PILL_TYPE_I18N_KEYS.city,
      };
    } else if (location.type === 'County') {
      return {
        ...location,
        typeKey: FILTER_SECTION_CONFIG.TOPIC.PILL_TYPE_I18N_KEYS.county,
      };
    } else if (location.type === 'State') {
      return {
        ...location,
        typeKey: FILTER_SECTION_CONFIG.TOPIC.PILL_TYPE_I18N_KEYS.state,
      };
    } else if (location.type === 'DMA') {
      return {
        ...location,
        typeKey: FILTER_SECTION_CONFIG.TOPIC.PILL_TYPE_I18N_KEYS.dma,
      };
    } else if (
      canUseInfluencerDisplayTargetArea &&
      location.type === 'TargetArea'
    ) {
      return {
        ...location,
        typeKey: FILTER_SECTION_CONFIG.TOPIC.PILL_TYPE_I18N_KEYS.targetArea,
      };
    }

    return location;
  });

  if (!canUseInfluencerDisplayTargetArea)
    return locationsPills.filter(location => location.type !== 'TargetArea');

  return locationsPills;
};

export const locationToPill = location => {
  if (location.type === 'Country') {
    return {
      ...location,
      typeKey: FILTER_SECTION_CONFIG.TOPIC.PILL_TYPE_I18N_KEYS.country,
    };
  } else if (location.type === 'City') {
    return {
      ...location,
      typeKey: FILTER_SECTION_CONFIG.TOPIC.PILL_TYPE_I18N_KEYS.city,
    };
  } else if (location.type === 'County') {
    return {
      ...location,
      typeKey: FILTER_SECTION_CONFIG.TOPIC.PILL_TYPE_I18N_KEYS.county,
    };
  } else if (location.type === 'State') {
    return {
      ...location,
      typeKey: FILTER_SECTION_CONFIG.TOPIC.PILL_TYPE_I18N_KEYS.state,
    };
  } else if (location.type === 'DMA') {
    return {
      ...location,
      typeKey: FILTER_SECTION_CONFIG.TOPIC.PILL_TYPE_I18N_KEYS.dma,
    };
  } else if (
    canUseInfluencerDisplayTargetArea &&
    location.type === 'TargetArea'
  ) {
    return {
      ...location,
      typeKey: FILTER_SECTION_CONFIG.TOPIC.PILL_TYPE_I18N_KEYS.targetArea,
    };
  }
  return location;
};

export const getLocations = (searchText = '') => async (dispatch, getState) => {
  const searchTextLength = searchText.length;
  const state = getState();
  const locations = state.influencerSearch.searchResults.locations;

  if (searchTextLength <= 2 && locations.popular && locations.data.length > 0) {
    return;
  }

  const encoded = encodeURIComponent(searchText);
  dispatch({
    type: GET_LOCATIONS,
  });

  try {
    const localCacheTtl = 10 * 60;

    const keyword =
      searchText && searchText.length > 2 ? `?keyword=${encoded}` : '';

    const result = await performGet(
      `${INFLUENCER_HUB_ENDPOINT}/suggestions/locations${keyword}`,
      undefined,
      undefined,
      undefined,
      canUseSuggestionsFECaching,
      false,
      localCacheTtl,
    );

    const dispatchType =
      searchText.length > 2
        ? GET_LOCATIONS_SUCCESS
        : GET_POPULAR_LOCATIONS_SUCCESS;

    dispatch({
      type: dispatchType,
      payload: getLocationsPills(result.data.data) || [],
    });
  } catch (e) {
    dispatch({
      type: GET_LOCATIONS_ERROR,
      payload: e.message,
    });
  }
};

export const getPillSectionLocations = (searchText = '') => async (
  dispatch,
  getState,
) => {
  dispatch({
    type: GET_PILL_SECTION_LOCATIONS_SUCCESS,
    payload: [],
  });
  if (searchText.length <= 2) {
    dispatch({
      type: GET_PILL_SECTION_LOCATIONS_SUCCESS,
      payload: [],
    });
  } else {
    dispatch({
      type: GET_PILL_SECTION_LOCATIONS,
    });

    try {
      const result = await performGet(
        `${INFLUENCER_HUB_ENDPOINT}/suggestions/locations?keyword=${searchText}`,
      );

      if (getState().influencerSearch.pageState.locations.searchText === '') {
        return;
      }

      const groupedResult = [
        {
          title: '',
          data: getLocationsPills(result.data.data),
        },
      ];

      dispatch({
        type: GET_PILL_SECTION_LOCATIONS_SUCCESS,
        payload: groupedResult,
      });
    } catch (e) {
      dispatch({
        type: GET_PILL_SECTION_LOCATIONS_ERROR,
        payload: e.message,
      });
    }
  }
};

export const getOutlets = (searchText = '') => async (dispatch, getState) => {
  const searchTextLength = searchText.length;
  const state = getState();
  const outlets = state.influencerSearch.searchResults.outlets;

  if (searchTextLength <= 2 && outlets.popular && outlets.data.length > 0) {
    return;
  }

  const encoded = encodeURIComponent(searchText);
  dispatch({
    type: GET_OUTLETS,
  });

  try {
    const useLocalCache = true;
    const localCacheTtl = 10 * 60; // 10 min in seconds
    const keyword =
      searchText && searchText.length > 2 ? `?keyword=${encoded}` : '';
    const result = await performGet(
      `${INFLUENCER_HUB_ENDPOINT}/suggestions/outlets${keyword}`,
      undefined,
      undefined,
      useLocalCache,
      false,
      localCacheTtl,
    );
    const dispatchType =
      searchText.length > 2 ? GET_OUTLETS_SUCCESS : GET_POPULAR_OUTLETS_SUCCESS;
    const miniCardMap = isEmpty(result.data.data)
      ? []
      : result.data.data.map(item => {
          return {
            ...item,
            miniCardData: item.id,
          };
        });

    dispatch({
      type: dispatchType,
      payload: miniCardMap,
    });
  } catch (e) {
    dispatch({
      type: GET_OUTLETS_ERROR,
      payload: e.message,
    });
    dispatch({
      type: searchText.length > 2 ? RESET_OUTLETS : RESET_POPULAR_OUTLETS,
    });
  }
};

export const getPillSectionOutlets = (searchText = '') => async dispatch => {
  if (searchText.length <= 2) {
    dispatch({
      type: GET_PILL_SECTION_OUTLETS_SUCCESS,
      payload: [],
    });
  } else {
    dispatch({
      type: GET_PILL_SECTION_OUTLETS,
    });

    const encoded = encodeURIComponent(searchText);
    try {
      const result = await performGet(
        `${INFLUENCER_HUB_ENDPOINT}/suggestions/outlets?parentsOnly=true&keyword=${encoded}`,
      );

      const getName = (name, rdsIds) => {
        if (rdsIds) {
          const idsCount = rdsIds.split(',').length - 1;
          return idsCount > 0 ? `${name} (${idsCount})` : name;
        }

        return name;
      };

      const pillsSectionOutlets = isEmpty(result.data.data)
        ? []
        : result.data.data.map(item => {
            return {
              ...item,
              name: getName(item.name, item.rdsIds),
            };
          });

      const groupedResult = [
        {
          title: '',
          data: pillsSectionOutlets,
        },
      ];

      dispatch({
        type: GET_PILL_SECTION_OUTLETS_SUCCESS,
        payload: groupedResult,
      });
    } catch (e) {
      dispatch({
        type: GET_PILL_SECTION_OUTLETS_ERROR,
        payload: e.message,
      });
    }
  }
};

export const getInfluencers = (
  searchText = '',
  privateInfluencerMatches = false,
) => async (dispatch, getState) => {
  const searchTextLength = searchText.length;
  const state = getState();
  const influencers = state.influencerSearch.searchResults.influencers;
  const searchSuffix = privateInfluencerMatches ? '-full' : '';

  if (
    searchTextLength <= 2 &&
    influencers.popular &&
    influencers.data.length > 0
  ) {
    return;
  }

  const encoded = encodeURIComponent(searchText);

  dispatch({
    type: privateInfluencerMatches
      ? GET_PRIVATE_INFLUENCER_MATCHES
      : GET_INFLUENCERS,
  });

  try {
    const useLocalCache = true;
    const localCacheTtl = 10 * 60; // 10 min in seconds
    const keyword =
      searchText && searchText.length > 2 ? `?keyword=${encoded}` : '';
    const result = await performGet(
      `${INFLUENCER_HUB_ENDPOINT}/suggestions/influencers${searchSuffix}${keyword}`,
      encoded,
      undefined,
      useLocalCache,
      false,
      localCacheTtl,
    );

    const miniCardMap = isEmpty(result.data.data)
      ? []
      : result.data.data.map(item => {
          return {
            ...item,
            miniCardData: item.id,
          };
        });

    if (privateInfluencerMatches) {
      dispatch({
        type: GET_PRIVATE_INFLUENCER_MATCHES_SUCCESS,
        payload: miniCardMap,
        pagination: {
          currentPageNumber: 1,
          pageSize: miniCardMap.length,
          totalHits: miniCardMap.length,
          totalPages: 1,
        },
      });
    } else if (searchText.length > 2) {
      dispatch({
        type: GET_INFLUENCERS_SUCCESS,
        hits: miniCardMap,
        pagination: {
          currentPageNumber: 1,
          pageSize: miniCardMap.length,
          totalHits: miniCardMap.length,
          totalPages: 1,
        },
      });
    } else {
      dispatch({
        type: GET_POPULAR_INFLUENCERS_SUCCESS,
        payload: miniCardMap,
        pagination: {
          currentPageNumber: 1,
          pageSize: miniCardMap.length,
          totalHits: miniCardMap.length,
          totalPages: 1,
        },
      });
    }
  } catch (e) {
    dispatch({
      type: privateInfluencerMatches
        ? GET_PRIVATE_INFLUENCER_MATCHES_ERROR
        : GET_INFLUENCERS_ERROR,
      payload: e.message,
    });
  }
};

export const updateSearchText = (searchText = '') => dispatch => {
  dispatch({
    type: UPDATE_SEARCH_TEXT,
    payload: searchText,
  });
};

export const updateSearchTopicText = (searchText = '') => dispatch => {
  dispatch({
    type: UPDATE_TOPIC_SEARCH_TEXT,
    payload: searchText,
  });
};

export const updateSearchLocationText = (searchText = '') => dispatch => {
  dispatch({
    type: UPDATE_LOCATION_SEARCH_TEXT,
    payload: searchText,
  });
};

export const updateSearchOutletText = (searchText = '') => dispatch => {
  dispatch({
    type: UPDATE_OUTLET_SEARCH_TEXT,
    payload: searchText,
  });
};

export const performAllSearches = (searchText = '') => dispatch => {
  dispatch(getOutlets(searchText));
  dispatch(getLocations(searchText));
  dispatch(getTopics(searchText));
  dispatch(getInfluencers(searchText));
  dispatch(updateSearchText(searchText));
};

export const getInitialContactsSuggestions = dispatch => {
  dispatch(getOutlets(''));
  dispatch(getInfluencers(''));
};

export const getInfluencersAndOutletsForSelectedTopic = searchText => dispatch => {
  dispatch({
    type: CANCEL_TOPIC_SEARCH_TEXT_MODE,
  });
  if (searchText) {
    dispatch(getOutlets(searchText));
    dispatch(getInfluencers(searchText));
    dispatch({
      type: GET_TOPICS_SUCCESS,
      payload: [],
    });
    dispatch({
      type: GET_LOCATIONS_SUCCESS,
      payload: [],
    });
  }
};

export const setSortField = sortField => async dispatch => {
  dispatch({
    type: SET_SORT_FIELD,
    payload: sortField,
  });

  dispatch(
    getSearchResults([
      INFLUENCERS_HUB_SEARCH_CRITERIA_NAMES.influencerType,
      INFLUENCERS_HUB_SEARCH_INFO_TYPE.aggregations,
    ]),
  );
};

export const resetSearchResultSortSelection = () => dispatch => {
  const defaultSort = find(IHUB_SORT_BY_TYPES, {
    id: 'relevance',
  }).id;
  find(IHUB_SORT_BY_TYPES, {
    selected: true,
  }).selected = false;
  find(IHUB_SORT_BY_TYPES, {
    id: defaultSort,
  }).selected = true;
  dispatch(setSortField(defaultSort));
};

export const clearSearch = () => dispatch => {
  dispatch({
    type: CLEAR_SEARCH,
  });
  dispatch({ type: INFLUENCERS_BULK_UNSELECT_ALL_INFLUENCERS });

  //Reset sort option to the default
  dispatch(resetSearchResultSortSelection());
};

export const updateKeywordCriteria = searchText => dispatch => {
  const hasConnectBasicFF = userHasFeatureFlag(FEATURES.connectBasic);
  const talkingAboutAsPill = {
    id: `talking-about-${searchText}`,
    name: searchText,
    type: FILTER_SECTION_CONFIG.TOPIC.PILL_TYPES.talkingAbout,
    typeKey: FILTER_SECTION_CONFIG.TOPIC.PILL_TYPE_I18N_KEYS.talkingAbout,
    booleanCondition: 'OR',
  };

  const keywordAsPill = {
    id: `keyword-${searchText}`,
    name: searchText,
    type: FILTER_SECTION_CONFIG.TOPIC.PILL_TYPES.keyword,
    typeKey: FILTER_SECTION_CONFIG.TOPIC.PILL_TYPE_I18N_KEYS.keyword,
    booleanCondition: 'OR',
  };

  dispatch({
    type: ADD_UPDATE_CHOSEN_TOPIC,
    payload: keywordAsPill,
  });

  if (!hasConnectBasicFF) {
    dispatch({
      type: ADD_UPDATE_CHOSEN_TOPIC,
      payload: talkingAboutAsPill,
    });
  }

  dispatch(syncTopicSearchCriteria());
};

export const addChosenTopicSimple = chosenTopic => dispatch => {
  dispatch({
    type: ADD_UPDATE_CHOSEN_TOPIC,
    payload: chosenTopic,
  });

  dispatch(syncTopicSearchCriteria());
};

export const addChosenTopic = (
  chosenTopic = {},
  getFullResults = false,
) => async dispatch => {
  dispatch({
    type: ADD_UPDATE_CHOSEN_TOPIC,
    payload: chosenTopic,
  });

  dispatch(syncTopicSearchCriteria());

  if (getFullResults) {
    dispatch(resetToPageOne());
    dispatch(getSearchResults());
  } else {
    dispatch(getSearchResultsPreview());
  }
};

export const removeChosenTopic = ({
  chosenTopic,
  getFullResults = false,
}) => async (dispatch, getState) => {
  if (chosenTopic) {
    dispatch({
      type: REMOVE_CHOSEN_TOPIC,
      payload: chosenTopic,
    });
  } else {
    dispatch({
      type: SET_CHOSEN_TOPICS,
      payload: [],
    });
  }
  const state = getState();
  const pageState = state.influencerSearch.pageState;

  if (pageState.topics.chosen.length === 0) {
    dispatch({
      type: GET_PILL_SECTION_TOPICS_SUCCESS,
      payload: [],
    });
  }

  if (
    pageState.topics.chosen.length === 0 &&
    pageState.locations.chosen.length === 0 &&
    pageState.outlets.chosen.length === 0
  ) {
    dispatch({
      type: UPDATE_TOPIC_SEARCH_CRITERIA,
      payload: {
        criteria: [],
        section: INFLUENCERS_HUB_SEARCH_CRITERIA_NAMES.topic,
      },
    });
    dispatch({
      type: UPDATE_TOPIC_SEARCH_CRITERIA,
      payload: {
        criteria: [],
        section: INFLUENCERS_HUB_SEARCH_CRITERIA_NAMES.talkingAbout,
      },
    });
    dispatch(performAllSearches());
  } else {
    dispatch(syncTopicSearchCriteria());

    if (getFullResults) {
      dispatch(resetToPageOne());
      dispatch(getSearchResults());
    } else {
      dispatch(getSearchResultsPreview());
    }
  }
};

export const clearSearchResultTopics = () => dispatch => {
  dispatch({
    type: SET_CHOSEN_TOPICS,
    payload: [],
  });
  dispatch({
    type: CLEAR_TOPIC_FILTER,
  });

  dispatch({
    type: UPDATE_TOPIC_SEARCH_CRITERIA,
    payload: {
      criteria: [],
      section: INFLUENCERS_HUB_SEARCH_CRITERIA_NAMES.topic,
    },
  });

  dispatch({
    type: UPDATE_TOPIC_SEARCH_CRITERIA,
    payload: {
      criteria: [],
      section: INFLUENCERS_HUB_SEARCH_CRITERIA_NAMES.talkingAbout,
    },
  });
  dispatch(resetToPageOne());
  dispatch(getSearchResults());
};

export const clearSearchResultOutlets = () => dispatch => {
  dispatch({
    type: SET_CHOSEN_OUTLETS,
    payload: [],
  });
  dispatch({
    type: CLEAR_OUTLET_FILTER,
  });

  dispatch({
    type: UPDATE_OUTLET_SEARCH_CRITERIA,
    payload: [],
  });
  dispatch(resetToPageOne());
  dispatch(getSearchResults());
};

export const clearSearchResultLocations = () => dispatch => {
  dispatch({
    type: SET_CHOSEN_LOCATIONS,
    payload: [],
  });
  dispatch({
    type: CLEAR_LOCATION_FILTER,
  });

  dispatch({
    type: UPDATE_LOCATION_SEARCH_CRITERIA,
    payload: [],
  });
  dispatch(resetToPageOne());
  dispatch(getSearchResults());
};

export const addChosenLocationSimple = (chosenLocation = {}) => (
  dispatch,
  getState,
) => {
  dispatch({
    type: ADD_UPDATE_CHOSEN_LOCATION,
    payload: chosenLocation,
  });

  const state = getState();
  const selectedLocations = state.influencerSearch.pageState.locations.chosen;
  const locationsCriteria = populateCriteriaArray(selectedLocations);

  dispatch({
    type: UPDATE_LOCATION_SEARCH_CRITERIA,
    payload: locationsCriteria,
  });
};

export const addChosenLocation = (
  chosenLocation = {},
  getFullResults = false,
) => async (dispatch, getState) => {
  dispatch({
    type: ADD_UPDATE_CHOSEN_LOCATION,
    payload: chosenLocation,
  });

  const state = getState();
  const selectedLocations = state.influencerSearch.pageState.locations.chosen;
  const locationsCriteria = populateCriteriaArray(selectedLocations);

  dispatch({
    type: UPDATE_LOCATION_SEARCH_CRITERIA,
    payload: locationsCriteria,
  });
  if (getFullResults) {
    dispatch(resetToPageOne());
    dispatch(getSearchResults());
  } else {
    dispatch(getSearchResultsPreview());
  }
};

export const showSelectedCardDetails = (
  selectedCard,
  options,
) => async dispatch => {
  // When doing a type-ahead search, the full influencer/outlet object with data
  // that can be shown in the MiniCard is returned with each search result. When
  // choosing pills, each sub-search is run individually (getTopics, etc.). The
  // full influencer/outlet object is NOT returned with the results in these cases.
  // Instead, only the ID (as a string) is pushed into selectedCard
  // and we have to go get the full data
  // based on the ID and populate the details.
  if (typeof selectedCard === 'object') {
    dispatch(
      getPreviewActionDispatcher({ influencer: selectedCard, ...options }),
    );
  } else {
    dispatch(getPreviewActionByIdDispatcher({ id: selectedCard, ...options }));
  }
};

export const removeChosenLocation = ({
  chosenLocation,
  getFullResults = false,
}) => (dispatch, getState) => {
  dispatch({
    type: REMOVE_CHOSEN_LOCATION,
    payload: chosenLocation,
  });

  const state = getState();
  const pageState = state.influencerSearch.pageState;

  if (
    pageState.topics.chosen.length === 0 &&
    pageState.locations.chosen.length === 0 &&
    pageState.outlets.chosen.length === 0
  ) {
    dispatch({
      type: UPDATE_LOCATION_SEARCH_CRITERIA,
      payload: [],
    });
    dispatch(performAllSearches());
  } else {
    const selectedLocations = state.influencerSearch.pageState.locations.chosen;
    const locationsCriteria = populateCriteriaArray(selectedLocations);

    dispatch({
      type: UPDATE_LOCATION_SEARCH_CRITERIA,
      payload: locationsCriteria,
    });
    if (getFullResults) {
      dispatch(resetToPageOne());
      dispatch(getSearchResults());
    } else {
      dispatch(getSearchResultsPreview());
    }
  }
};

export const addChosenOutlet = (chosenOutlet, getFullResults = false) => async (
  dispatch,
  getState,
) => {
  dispatch({
    type: ADD_UPDATE_CHOSEN_OUTLET,
    payload: chosenOutlet,
  });

  const state = getState();
  const selectedOutlets = state.influencerSearch.pageState.outlets.chosen;
  const outletsCriteria = populateCriteriaArray(selectedOutlets);

  dispatch({
    type: UPDATE_OUTLET_SEARCH_CRITERIA,
    payload: outletsCriteria,
  });
  if (getFullResults) {
    dispatch(resetToPageOne());
    dispatch(getSearchResults());
  } else {
    dispatch(getSearchResultsPreview());
  }
};

export const removeChosenOutlet = ({
  chosenOutlet,
  getFullResults = false,
}) => (dispatch, getState) => {
  dispatch({
    type: REMOVE_CHOSEN_OUTLET,
    payload: chosenOutlet,
  });

  const state = getState();
  const pageState = state.influencerSearch.pageState;

  if (
    pageState.topics.chosen.length === 0 &&
    pageState.locations.chosen.length === 0 &&
    pageState.outlets.chosen.length === 0
  ) {
    dispatch({
      type: UPDATE_OUTLET_SEARCH_CRITERIA,
      payload: [],
    });
    dispatch(performAllSearches());
  } else {
    const selectedOutlets = state.influencerSearch.pageState.outlets.chosen;
    const outletsCriteria = populateCriteriaArray(selectedOutlets);

    dispatch({
      type: UPDATE_OUTLET_SEARCH_CRITERIA,
      payload: outletsCriteria,
    });
    if (getFullResults) {
      dispatch(resetToPageOne());
      dispatch(getSearchResults());
    } else {
      dispatch(getSearchResultsPreview());
    }
  }
};

export const getSearchResultsForInfluencerType = (
  searchText,
  influencerType,
) => dispatch => {
  const searchInfluencerType = getSearchCriteriaForInfluencerType(
    influencerType,
  );

  const influencerTypeCriteria = {
    influencerType,
    searchTextCriteria: [
      getCriteria({
        searchString: searchText,
        booleanCondition: '',
      }),
    ],
    searchInfluencerTypeCriteria: searchInfluencerType,
  };

  dispatch({
    type: UPDATE_INFLUENCER_TYPE_SEARCH_CRITERIA,
    payload: influencerTypeCriteria,
  });
  dispatch(getSearchResults());
};

export const mediaTypeToPill = type => ({
  ...type,
  description: type.name,
  referenceType: 'media-types',
});

export const jobRoleToPill = role => ({
  ...role,
  id: role.id.toString(),
});

export const addAdvancedSearchFilterSimple = filters => dispatch => {
  dispatch({
    type: ADD_ADVANCED_SEARCH_CRITERIA,
    payload: castArray(filters),
  });
};

export const addChosenJobRoleSimple = jobRoleFilter => dispatch => {
  dispatch({
    type: SET_JOB_ROLE_FILTER_SIMPLE,
    payload: jobRoleFilter,
  });
};

export const getSuggestedPillsOnClick = (noUpdate = []) => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const selectedListId = state.influencerSearch.pageState.selectedListId;
  const dispatchedSearchPage =
    selectedListId === 'private'
      ? 'private'
      : selectedListId === 'unsubscribed'
      ? 'unsubscribed'
      : selectedListId
      ? 'savedList'
      : 'search';
  dispatchPillSections(noUpdate, dispatch);
  const endpoints = {
    private: INFLUENCER_GET_PRIVATE_INFLUENCERS_URL,
    savedList: INFLUENCER_SAVED_LISTS_LIST_MEMBERS_ENDPOINT.replace(
      '<listId>',
      selectedListId,
    ),
    unsubscribed: INFLUENCER_GET_UNSUBSCRIBED_INFLUENCERS_URL,
    search: `${INFLUENCER_HUB_ENDPOINT}/search`,
  };
  const searchEndpoint = endpoints[dispatchedSearchPage];
  // Get aggregations for pills
  dispatch(dispatchPillSectionsSuccess(searchEndpoint, noUpdate));
};

export const filterResultsonApply = () => dispatch => {
  dispatch(resetToPageOne());
  dispatch(getSearchResults(false));
};

export const addAdvancedSearchFilters = (
  filters,
  getFullResults = false,
  hasConnectApplyFilter = false,
) => dispatch => {
  dispatch({
    type: ADD_ADVANCED_SEARCH_CRITERIA,
    payload: castArray(filters),
  });

  if (!hasConnectApplyFilter) {
    if (getFullResults) {
      dispatch(resetToPageOne());
      dispatch(getSearchResults());
    } else {
      dispatch(getSearchResultsPreview());
    }
  } else {
    dispatch(getSuggestedPillsOnClick());
  }
};

export const removeAdvancedSearchFilters = (
  filters,
  getFullResults = false,
  hasConnectApplyFilter = false,
) => dispatch => {
  dispatch({
    type: REMOVE_ADVANCED_SEARCH_CRITERIA,
    payload: castArray(filters),
  });

  if (!hasConnectApplyFilter) {
    if (getFullResults) {
      dispatch(resetToPageOne());
      dispatch(getSearchResults());
    } else {
      dispatch(getSearchResultsPreview());
    }
  } else {
    dispatch(getSuggestedPillsOnClick());
  }
};

export const clearAdvancedSearchFilters = (
  referenceType,
  getFullResults = false,
) => dispatch => {
  dispatch({
    type: CLEAR_ADVANCED_SEARCH_CRITERIA,
    payload: referenceType,
  });

  if (getFullResults) {
    dispatch(resetToPageOne());
    dispatch(getSearchResults());
  } else {
    dispatch(getSearchResultsPreview());
  }
};

export const setContactAttributeIds = selectedIds => dispatch => {
  dispatch({
    type: SET_CONTACT_ATTRIBUTES_IDS,
    payload: selectedIds,
  });
};

export const clearUnsubscribedFilter = () => dispatch => {
  dispatch({
    type: REMOVE_SUBSCRIBED_FILTER,
  });
};

export const removeUnsubscribedFilters = (
  hasConnectApplyFilter = false,
) => dispatch => {
  dispatch(clearUnsubscribedFilter());
  if (!hasConnectApplyFilter) {
    dispatch(resetPageNumberAndGetNewSearchResults());
  } else {
    dispatch(getSuggestedPillsOnClick());
  }
};

export const setUnsubscribedFilter = listType => dispatch => {
  const searchString = listType === 'unsubscribed' ? 'false' : 'true';
  const unsubscribedSearchCriteria = {
    booleanCondition: 'FILTER',
    criteriaOrder: 1,
    locationType: 'city',
    searchId: 0,
    searchString: searchString,
  };

  dispatch({
    type: ADD_SUBSCRIBED_FILTER,
    payload: castArray(unsubscribedSearchCriteria),
  });
};

export const addSubscribedFilters = (
  hasConnectApplyFilter = false,
) => dispatch => {
  dispatch(setUnsubscribedFilter());
  if (!hasConnectApplyFilter) {
    dispatch(resetPageNumberAndGetNewSearchResults());
  } else {
    dispatch(getSuggestedPillsOnClick());
  }
};

export const toggleContactAttributesFilters = ({
  hasAppliedUnsubscribedFilter,
  hasConnectApplyFilter = false,
}) => dispatch => {
  const addOrRemoveUnsubscribedAttribute = hasAppliedUnsubscribedFilter
    ? addSubscribedFilters(hasConnectApplyFilter)
    : removeUnsubscribedFilters(hasConnectApplyFilter);

  dispatch(addOrRemoveUnsubscribedAttribute);
};

export const toggleAdvancedSearchFilters = (
  filters,
  checked,
  getFullResults = false,
  hasConnectApplyFilter = false,
) => dispatch => {
  dispatch(
    checked
      ? removeAdvancedSearchFilters(
          filters,
          getFullResults,
          hasConnectApplyFilter,
        )
      : addAdvancedSearchFilters(
          filters,
          getFullResults,
          hasConnectApplyFilter,
        ),
  );
};

export const changePageNumber = (pageNumber = 1) => async dispatch => {
  dispatch({
    type: CHANGE_PAGE_NUMBER,
    payload: { pageNumber },
  });
  try {
    const influencerDataofCurrentPage = await dispatch(
      getSearchResults([
        INFLUENCERS_HUB_SEARCH_CRITERIA_NAMES.influencerType,
        INFLUENCERS_HUB_SEARCH_INFO_TYPE.aggregations,
      ]),
    );

    dispatch(fetchNewInfluencers(influencerDataofCurrentPage, pageNumber));
  } catch (error) {
    dispatch(fetchNewInfluencers(error, pageNumber));
  }
};

export const setSortingDirection = ({
  sortingField,
  sortingDirection,
}) => dispatch => {
  dispatch({
    type: SET_SORT_FIELD,
    payload: sortingField,
  });

  dispatch({
    type: SET_SORT_DIRECTION,
    payload: sortingDirection,
  });
};

export const setSearchWithSort = (sortField, sortDir) => async dispatch => {
  dispatch(
    setSortingDirection({
      sortingField: sortField,
      sortingDirection: sortDir,
    }),
  );

  dispatch(resetToPageOne());
  dispatch(
    getSearchResults([
      INFLUENCERS_HUB_SEARCH_CRITERIA_NAMES.influencerType,
      INFLUENCERS_HUB_SEARCH_INFO_TYPE.aggregations,
    ]),
  );
};
const getPillFilterList = ({ prevFilters, filterToAdd }) => {
  const filterAlreadyExists = !!prevFilters.find(
    filter => filter.id === filterToAdd.id,
  );

  return filterAlreadyExists ? [...prevFilters] : [...prevFilters, filterToAdd];
};

const addFilterToListActionDispatcher = (filter, listType) => (
  dispatch,
  getState,
) => {
  const state = getState();
  const locale = activeLanguageSelector(state);
  let actionType;
  let prevFilters;

  switch (listType) {
    case 'location':
      actionType = GET_PILL_SECTION_FILTER_LOCATIONS_SUCCESS;
      prevFilters = pillSectionFilterLocationsSelector(state).data[0].data;
      break;
    case 'outlet':
      actionType = GET_PILL_SECTION_FILTER_OUTLETS_SUCCESS;
      prevFilters = pillSectionFilterOutletsSelector(state).data[0].data;
      break;
    case 'topic':
      actionType = GET_PILL_SECTION_FILTER_TOPICS_SUCCESS;
      prevFilters = pillSectionFilterTopicsSelector(state).data[0].data;
      break;
    case 'language':
      actionType = GET_PILL_SECTION_FILTER_LANGUAGE_SUCCESS;
      prevFilters = pillSectionFilterLanguagesSelector(state).data[0].data;
      break;
    default:
      break;
  }

  let nextFilters = getPillFilterList({ prevFilters, filterToAdd: filter });
  nextFilters = getUniquePills(nextFilters);
  nextFilters = nextFilters.sort((a, b) => sortPills(a, b, locale));

  const payload = [
    {
      title: 'FILTERS',
      data: nextFilters,
    },
  ];

  const action = {
    type: actionType,
    payload,
  };

  dispatch(action);
};

export const setSearchResultTopicFilter = (
  topicFilter,
  options,
  hasConnectApplyFilterFF,
) => dispatch => {
  if (options?.addToList) {
    dispatch(addFilterToListActionDispatcher(topicFilter, 'topic'));
  }

  dispatch({
    type: SET_TOPIC_FILTER,
    payload: topicFilter.id,
  });

  if (hasConnectApplyFilterFF) {
    dispatch(
      getSuggestedPillsOnClick([INFLUENCERS_HUB_SEARCH_CRITERIA_NAMES.topic]),
    );
  } else {
    dispatch(resetToPageOne());
    dispatch(getSearchResults([INFLUENCERS_HUB_SEARCH_CRITERIA_NAMES.topic]));
  }
};

export const setSearchContactByName = (
  criteria,
  resetSelectedInfluencers = false,
) => dispatch => {
  dispatch({
    payload: {
      criteria,
      section: INFLUENCERS_HUB_SEARCH_CRITERIA_NAMES.contact,
    },
    type: UPDATE_SEARCH_BY_CONTACT_NAME,
  });

  dispatch(resetToPageOne(resetSelectedInfluencers));
};

export const setSearchOutletByName = criteria => dispatch => {
  dispatch({
    payload: {
      criteria,
      section: INFLUENCERS_HUB_SEARCH_CRITERIA_NAMES.outlet,
    },
    type: UPDATE_SEARCH_BY_OUTLET_NAME,
  });
};

export const searchContactByName = (
  criteria,
  resetSelectedInfluencers = false,
) => dispatch => {
  dispatch(setSearchContactByName(criteria, resetSelectedInfluencers));
  dispatch(getSearchResults([INFLUENCERS_HUB_SEARCH_CRITERIA_NAMES.contact]));
  dispatch(bulkMultiSelectAllInfluencers(true));
};

export const searchOutletByName = criteria => dispatch => {
  dispatch(setSearchOutletByName(criteria));
};

export const setSearchResultLocationFilter = (
  locationFilter,
  options,
  hasConnectApplyFilterFF,
) => dispatch => {
  if (options?.addToList) {
    dispatch(addFilterToListActionDispatcher(locationFilter, 'location'));
  }

  dispatch({
    type: SET_LOCATION_FILTER,
    payload: locationFilter.id,
  });

  if (hasConnectApplyFilterFF) {
    dispatch(
      getSuggestedPillsOnClick([
        INFLUENCERS_HUB_SEARCH_CRITERIA_NAMES.location,
      ]),
    );
  } else {
    dispatch(resetToPageOne());
    dispatch(
      getSearchResults([INFLUENCERS_HUB_SEARCH_CRITERIA_NAMES.location]),
    );
  }
};

export const setSearchResultOutletFilter = (
  outletFilter,
  options,
  hasConnectApplyFilterFF,
) => dispatch => {
  if (options?.addToList) {
    dispatch(addFilterToListActionDispatcher(outletFilter, 'outlet'));
  }

  dispatch({
    type: SET_OUTLET_FILTER,
    payload: outletFilter.id,
  });

  if (hasConnectApplyFilterFF) {
    dispatch(
      getSuggestedPillsOnClick([INFLUENCERS_HUB_SEARCH_CRITERIA_NAMES.outlet]),
    );
  } else {
    dispatch(resetToPageOne());
    dispatch(getSearchResults([INFLUENCERS_HUB_SEARCH_CRITERIA_NAMES.outlet]));
  }
};

export const setSearchResultJobRoleFilter = (
  jobRoleFilter,
  hasConnectApplyFilterFF,
) => dispatch => {
  dispatch({
    type: SET_JOB_ROLE_FILTER,
    payload: jobRoleFilter,
  });

  if (hasConnectApplyFilterFF) {
    dispatch(
      getSuggestedPillsOnClick([INFLUENCERS_HUB_SEARCH_CRITERIA_NAMES.jobRole]),
    );
  } else {
    dispatch(resetToPageOne());
    dispatch(getSearchResults([INFLUENCERS_HUB_SEARCH_CRITERIA_NAMES.jobRole]));
  }
};

const getLanguageFilters = ({ language, state }) => {
  const languages = pillSectionFilterLanguagesSelector(state).data;
  const languageFilters = first(languages);
  const selectedLanguageIndex = findIndex(languageFilters.data, {
    id: language,
  });

  if (selectedLanguageIndex !== -1) {
    languageFilters.data[selectedLanguageIndex].selected = !languageFilters
      .data[selectedLanguageIndex].selected;
  }

  languageFilters.data = sortFilterPills(languageFilters.data);

  return languageFilters;
};

export const setSearchResultLanguageFilter = (
  language,
  hasConnectApplyFilterFF,
) => (dispatch, getState) => {
  const state = getState();
  const languageFilters = getLanguageFilters({ language, state });

  dispatch({
    type: SET_LANGUAGE_FILTER,
    payload: languageFilters,
  });

  if (hasConnectApplyFilterFF) {
    dispatch(
      getSuggestedPillsOnClick([
        INFLUENCERS_HUB_SEARCH_CRITERIA_NAMES.language,
      ]),
    );
  } else {
    dispatch(resetToPageOne());
    dispatch(
      getSearchResults([INFLUENCERS_HUB_SEARCH_CRITERIA_NAMES.language]),
    );
  }
};

export const clearLanguageFilter = () => dispatch => {
  dispatch({
    type: CLEAR_LANGUAGE_FILTER,
  });
  dispatch(resetToPageOne());
  dispatch(getSearchResults());
};

export const clearAllFilters = (isNotSearchResult, listType) => dispatch => {
  if (isNotSearchResult) {
    dispatch({
      type: SET_CHOSEN_TOPICS,
      payload: [],
    });

    dispatch({
      type: CLEAR_TOPIC_FILTER,
    });

    dispatch({
      type: UPDATE_TOPIC_SEARCH_CRITERIA,
      payload: {
        criteria: [],
        section: INFLUENCERS_HUB_SEARCH_CRITERIA_NAMES.topic,
      },
    });

    dispatch({
      type: UPDATE_TOPIC_SEARCH_CRITERIA,
      payload: {
        criteria: [],
        section: INFLUENCERS_HUB_SEARCH_CRITERIA_NAMES.talkingAbout,
      },
    });
  }

  dispatch(setUnsubscribedFilter(listType));

  if (!isNotSearchResult || listType === 'saved-list') {
    dispatch({
      type: CLEAR_LANGUAGE_FILTER,
    });
  }

  dispatch({
    type: CLEAR_JOB_ROLE_FILTER,
  });

  dispatch({
    type: SET_CHOSEN_LOCATIONS,
    payload: [],
  });

  dispatch({
    type: CLEAR_LOCATION_FILTER,
  });

  dispatch({
    type: UPDATE_LOCATION_SEARCH_CRITERIA,
    payload: [],
  });

  dispatch({
    type: SET_CHOSEN_OUTLETS,
    payload: [],
  });

  dispatch({
    type: CLEAR_OUTLET_FILTER,
  });

  dispatch({
    type: UPDATE_OUTLET_SEARCH_CRITERIA,
    payload: [],
  });

  dispatch({
    type: CLEAR_REACH_AND_IMPACT_FILTER,
  });
};

export const clearSearchResultJobRoles = dispatch => {
  dispatch({
    type: CLEAR_JOB_ROLE_FILTER,
  });
  dispatch(resetToPageOne());
  dispatch(getSearchResults());
};

export const updateReachAndImpactFilter = (name, range) => dispatch => {
  dispatch({
    type: UPDATE_REACH_AND_IMPACT_FILTER,
    payload: {
      name,
      range,
    },
  });

  dispatch(resetToPageOne());
  dispatch(getSearchResults());
};

export const clearReachAndImpactFilter = dispatch => {
  dispatch({
    type: CLEAR_REACH_AND_IMPACT_FILTER,
  });
  dispatch(resetToPageOne());
  dispatch(getSearchResults());
};

export const cancelInfluencerRequest = () => (dispatch, getState) => {
  const state = getState();
  const influencers = state.influencerSearch.searchResults.influencers;
  if (influencers.cancelTokenSource && influencers.loading) {
    influencers.cancelTokenSource.cancel('api request cancelled');
    dispatch({
      type: GET_INFLUENCERS_CANCELED,
    });
  }
};

export const clearSearchDispatcher = () => dispatch =>
  dispatch({ type: CLEAR_SEARCH });

export default influencerSearchReducer;
