import React, { Component } from 'react';

import { Button } from '@cision/rover-ui';
import isEmpty from 'lodash/isEmpty';
import throttle from 'lodash/throttle';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { actions, Form } from 'react-redux-form';
import { withRouter } from 'react-router-dom';

import {
  Button as TkUiButton,
  ConfirmModal,
  Modal,
  SvgIcon,
  StatusBar,
  Addon,
  Media,
} from '@trendkite/ui';
import { EmailForm } from 'components/email-composer';
import ScheduleModal from 'components/global/ScheduleModal';
import { validators } from 'components/outreach/outreach-validators';
import OutreachDiscardModal from 'components/outreach/OutreachDiscardModal';
import OutreachSendingModal from 'components/outreach/OutreachSendingModal';
import {
  handleComposeModalRedirect,
  getFormattedDateWithTimestamp,
  getTimeInTimezoneFromSchedule,
} from 'components/outreach/utils';
import { DEV_FEATURES } from 'constants/constants';
import { OUTREACH_CONTACT_TYPES } from 'constants/outreach-message';
import globalMessages from 'i18n/Global.messages';
import TranslatedMessage from 'i18n/TranslatedMessage';
import { emailVerifiedEmailsSelector } from 'pages/Email/Email-selector';
import {
  toggleComposeModalActionCreator,
  closeComposeModalActionCreator,
} from 'reducers/outreach/outreach-compose-modal';
import {
  deleteOutreachDraftMessageById,
  openComposeModalWithValuesActionCreator,
  removeDraftOutreachScheduleActionDispatcher,
  saveDraftOutreachMessageActionDispatcher,
  saveDraftOutreachScheduleActionDispatcher,
} from 'reducers/outreach/outreach-drafts';
import {
  sendOutreachMessageFormActionCreator,
  sendOutreachMessageFormTestActionCreator,
  clearMessageSaveErrorActionDispatcher,
} from 'reducers/outreach/outreach-messages';
import {
  toggleScheduleModalActionCreator,
  closeScheduleModalActionCreator,
  closeScheduleModalErrorActionCreator,
} from 'reducers/outreach/outreach-schedule-modal';
import { hasDevFeatureFlagSelector } from 'selectors/account';
import {
  defaultOutreachIntegrationSelector,
  isComposeModalOpenSelector,
  isOutreachComposeFormPristineSelector,
  isOutreachComposeFormUnmodifiedReplySelector,
  isOutreachComposeFormValidSelector,
  isScheduleModalOpenSelector,
  isScheduleModalErrorOpenSelector,
  outreachComposeFormValuesSelector,
  validOwnedIntegrationsSelector,
  isOutreachComposeFormSubjectFieldValidSelector,
  isOutreachComposeFormSubjectFieldTouchedSelector,
  hasOutreachComposeFormRequirementsSelector,
} from 'selectors/outreach';
import { userHasDevFeatureFlag } from 'services/feature-service/feature-service';

import {
  convertDateTimeSelectionToUnix,
  unixTimeIsInFuture,
} from 'utils/times/times';

import './outreach-compose-modal.scss';

import messages from './OutreachNotConnected.messages';

const formModel = 'outreachComposeForm';

const mapStateToProps = state => ({
  defaultIntegration: defaultOutreachIntegrationSelector(state),
  error: state.outreach.newMessageSaveError,
  hasJortsDrafts: userHasDevFeatureFlag(DEV_FEATURES.jortsDrafts),
  ownedIntegrations: validOwnedIntegrationsSelector(state),
  verifiedEmails: emailVerifiedEmailsSelector(state),
  isOpen: isComposeModalOpenSelector(state),
  isScheduleModalOpen: isScheduleModalOpenSelector(state),
  isScheduleModalErrorOpen: isScheduleModalErrorOpenSelector(state),
  isPristine: isOutreachComposeFormPristineSelector(state),
  isUnmodifiedReply: isOutreachComposeFormUnmodifiedReplySelector(state),
  isValid: isOutreachComposeFormValidSelector(state),
  isValidForSendgrid: hasOutreachComposeFormRequirementsSelector(state),
  isSaving: state.outreach.newMessageSaving,
  composeForm: outreachComposeFormValuesSelector(state),
  hasDevFeatureFlag: hasDevFeatureFlagSelector(state),
  isSubjectFieldValid: isOutreachComposeFormSubjectFieldValidSelector(state),
  isSubjectFieldTouched: isOutreachComposeFormSubjectFieldTouchedSelector(
    state,
  ),
});

const mapDispatchToProps = dispatch => ({
  clearMessageSaveError: () =>
    dispatch(clearMessageSaveErrorActionDispatcher()),
  deleteDraft: draftId => {
    dispatch(deleteOutreachDraftMessageById(draftId)).then(() => {
      dispatch(closeComposeModalActionCreator());
      dispatch(actions.reset('outreachComposeForm'));
    });
  },
  removeDraftSchedule: draftId =>
    dispatch(removeDraftOutreachScheduleActionDispatcher(draftId)),
  resetForm: model => dispatch(actions.reset(model)),
  send: () => dispatch(sendOutreachMessageFormActionCreator()),
  sendTest: () => dispatch(sendOutreachMessageFormTestActionCreator()),
  setForm: (model, value) => dispatch(actions.change(model, value)),
  setValidity: (model, val) => dispatch(actions.setValidity(model, val)),
  submitForm: () => dispatch(actions.submit(formModel)),
  saveDraft: silentMode =>
    dispatch(saveDraftOutreachMessageActionDispatcher(silentMode)),
  saveDraftSchedule: (date, time, timezone, draftId) =>
    dispatch(
      saveDraftOutreachScheduleActionDispatcher(date, time, timezone, draftId),
    ),
  toggle: () => dispatch(toggleComposeModalActionCreator()),
  closeModal: () => dispatch(closeComposeModalActionCreator()),
  toggleScheduleModal: () => dispatch(toggleScheduleModalActionCreator()),
  closeScheduleModal: () => dispatch(closeScheduleModalActionCreator()),
  closeScheduleModalError: () =>
    dispatch(closeScheduleModalErrorActionCreator()),
  saveDraftAndCloseModal: () => {
    dispatch(saveDraftOutreachMessageActionDispatcher(false)).then(() => {
      dispatch(toggleComposeModalActionCreator());
      dispatch(actions.reset('outreachComposeForm'));
    });
  },
  openComposeModalWithValues: (values, saveDraft) =>
    dispatch(openComposeModalWithValuesActionCreator(values, { saveDraft })),
});

const SEND_DELAY = 2000;

class OutreachComposeModal extends Component {
  state = {
    isOpenDiscardModal: false,
    isOpenAreYouSureModal: false,
    isOpenSendingModal: false,
    isSendingTest: false,
    modifiedSideBar: false,
  };

  componentDidMount() {
    const {
      hasDevFeatureFlag,
      history,
      isOpen,
      location,
      openComposeModalWithValues,
    } = this.props;
    this.setInitialFromValues();

    if (!isOpen) {
      handleComposeModalRedirect({
        history,
        location,
        openComposeModalWithValues: values =>
          openComposeModalWithValues(values, {
            saveDraft: hasDevFeatureFlag(DEV_FEATURES.jortsDrafts),
          }),
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      defaultIntegration,
      error,
      isOpen,
      isSaving,
      ownedIntegrations,
      resetForm,
      closeModal,
    } = this.props;

    if (!isOpen) {
      return;
    }

    const { isSendingTest, modifiedSideBar } = this.state;

    if (modifiedSideBar) {
      this.setState({ modifiedSideBar: false });
    }

    if (defaultIntegration && !prevProps.defaultIntegration) {
      this.setInitialFromValues();
    }

    if (ownedIntegrations && !prevProps.ownedIntegrations) {
      this.setInitialFromValues();
    }

    if (isOpen && !prevProps.isOpen) {
      this.setInitialFromValues();
    }

    if (!isSaving && prevProps.isSaving && !error) {
      this.closeSendingModal();
      if (!isSendingTest) {
        resetForm('outreachComposeForm');
        closeModal();
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.sendTimeout);
  }

  onToggle = () => {
    if (this.props.hasJortsDrafts && !this.props.isSaving) {
      this.onClickSaveDraft();
    } else {
      if (this.props.isPristine) {
        this.props.toggle();
        return;
      }

      if (!this.state.isOpenDiscardModal) {
        // TODO: remove once JORTS_DRAFTS is released
        this.setState({ isOpenDiscardModal: true });
      }
    }
  };

  onClickDiscard = () => {
    this.setState({ isSendingTest: false });
    this.props.deleteDraft(this.props.composeForm.id);
  };

  onClickSaveDraft = () => {
    this.props.saveDraftAndCloseModal();
  };

  onClickSave = () => {
    this.props.submitForm();
  };

  onClickSendTest = () => {
    this.setState({
      isSendingTest: true,
      isOpenSendingModal: true,
    });
    this.props.sendTest();
  };

  onSubmit = () => {
    const noSubjectOrBody =
      !this.props.composeForm.subject && !this.props.composeForm.body;
    if (noSubjectOrBody || this.props.isUnmodifiedReply) {
      this.areYouSure();
    } else {
      this.sendWithDelay();
    }
  };

  onCancelAreYouSureModal = () => {
    this.setState({ isOpenAreYouSureModal: false });
  };

  onCancelScheduleModal = () => {
    this.props.closeScheduleModal();
  };

  onOpenScheduleModal = () => {
    this.props.toggleScheduleModal();
  };

  onConfirmScheduleModal = ({ date, time, selectedTimezone }) => {
    this.props.saveDraftSchedule(
      date,
      time,
      selectedTimezone,
      this.props.composeForm.id,
    );
  };

  onRemoveScheduleModal = () => {
    this.props.removeDraftSchedule(this.props.composeForm.id);
  };

  onConfirmAreYouSureModal = () => {
    this.setState({ isOpenAreYouSureModal: false });
    this.sendWithDelay();
  };

  onCancelDiscardModal = () => {
    // TODO: remove once JORTS_DRAFTS is released
    this.setState({ isOpenDiscardModal: false });
  };

  onCancelSendingModal = () => {
    this.setState({ isOpenSendingModal: false });
    clearTimeout(this.sendTimeout);
    if (this.props.error) {
      this.props.clearMessageSaveError();
    }
  };

  onConfirmDiscardModal = () => {
    this.setState({
      isOpenDiscardModal: false,
      isSendingTest: false,
    });
    this.props.toggle();
    this.props.resetForm('outreachComposeForm');
  };

  onScheduleModal = () => {
    this.setState({
      isOpenDiscardModal: false,
      isSendingTest: false,
    });
    this.props.toggle();
    this.props.resetForm('outreachComposeForm');
  };

  onRemoveSchedule = () => {
    this.onRemoveScheduleModal();
  };

  getOptionsFrom = () => {
    const {
      composeForm: { participatingIntegrations, replyToMessageId },
      defaultIntegration,
      ownedIntegrations,
      verifiedEmails,
    } = this.props;
    let allowedIntegrations = ownedIntegrations || [];

    if (replyToMessageId && !isEmpty(participatingIntegrations)) {
      allowedIntegrations = participatingIntegrations;
    }

    const hasSendGridPitchFF = userHasDevFeatureFlag(
      DEV_FEATURES.sendgridPitch,
    );

    const allowedIntegrationsWithEmailAddresses = hasSendGridPitchFF
      ? verifiedEmails?.emailAccounts || []
      : allowedIntegrations.filter(integration => !!integration.emailAddress);

    const options = allowedIntegrationsWithEmailAddresses.map(integration => ({
      contactType: OUTREACH_CONTACT_TYPES.user,
      email: integration.emailAddress,
      id: integration.id,
      name: integration.name || '',
    }));

    let selectedIntegration = options[0];

    if (defaultIntegration) {
      selectedIntegration =
        options.find(option => option.id === defaultIntegration.id) ||
        selectedIntegration;
    }

    return {
      options: [...options],
      selectedIntegration,
    };
  };

  setInitialFromValues = () => {
    const { options: optionsFrom, selectedIntegration } = this.getOptionsFrom();

    if (!optionsFrom.length) {
      return;
    }

    this.props.setForm('outreachComposeForm.from', selectedIntegration);
    this.props.setValidity('outreachComposeForm.from', true);
  };

  scheduleModalSelectionsAreValid = ({ date, time, timezone }) => {
    const timestamp = convertDateTimeSelectionToUnix(date, time, timezone);
    return unixTimeIsInFuture(timestamp);
  };

  throttledBackgroundSave = throttle(this.props.saveDraft, 1000);

  closeSendingModal = () => {
    this.setState({ isOpenSendingModal: false });
  };

  clearBccs = () => {
    this.props.setForm('outreachComposeForm.bcc', []);
  };

  clearCcs = () => {
    this.props.setForm('outreachComposeForm.cc', []);
  };

  toggleDiscardModal = () => {
    this.setState(state => ({ isOpenDiscardModal: !state.isOpenDiscardModal }));
  };

  areYouSure = () => {
    this.setState({ isOpenAreYouSureModal: true });
  };

  sendWithDelay = () => {
    this.setState({
      isOpenSendingModal: true,
      isSendingTest: false,
    });
    this.sendTimeout = setTimeout(() => {
      this.props.send();
    }, SEND_DELAY);
  };

  handleErrorConfirm = () => {
    this.props.closeScheduleModalError();
  };

  render() {
    const {
      composeForm,
      error,
      hasDevFeatureFlag,
      isOpen,
      isSaving,
      isScheduleModalOpen,
      isScheduleModalErrorOpen,
      isUnmodifiedReply,
      isSubjectFieldTouched,
      isSubjectFieldValid,
      showSaveDraft,
      isValidForSendgrid,
      isValid,
      intl,
    } = this.props;

    const { schedule } = composeForm;

    const showScheduleModal = hasDevFeatureFlag(DEV_FEATURES.jortsSchedule);
    const hasSendgridPitchFF = hasDevFeatureFlag(DEV_FEATURES.sendgridPitch);

    const {
      isOpenAreYouSureModal,
      isOpenDiscardModal,
      isOpenSendingModal,
      isSendingTest,
    } = this.state;
    const { options: optionsFrom } = this.getOptionsFrom();

    if (!optionsFrom.length) {
      return null;
    }

    const time = composeForm.schedule
      ? getTimeInTimezoneFromSchedule(composeForm.schedule)
      : undefined;

    const disableSendButton = hasSendgridPitchFF
      ? !isValidForSendgrid
      : !isValid;

    const emailBodyPlaceholder = hasSendgridPitchFF
      ? intl.formatMessage(messages.requiredBodyPlaceholder)
      : '';

    /* Fixed during noop refactor, revisit */
    /* eslint-disable jsx-a11y/no-autofocus */
    return (
      <Modal autoFocus={false} isOpen={isOpen} toggle={this.onToggle}>
        {/* eslint-enable jsx-a11y/no-autofocus */}
        <Modal.Header toggle={this.onToggle}>
          <TranslatedMessage {...messages.outreachComposeModalTitle} />
        </Modal.Header>
        <Modal.Body padding="0px">
          {showScheduleModal && schedule && (
            <StatusBar
              bordered
              type="info"
              modifiers={['flex', 'small', 'items-centered']}
            >
              <Media style={{ alignItems: 'center' }}>
                <Media.Item>
                  <SvgIcon
                    icon="timer"
                    color="info"
                    width={20}
                    height={20}
                    modifiers={['right-small']}
                  />
                </Media.Item>
                <Media.Body>
                  <p>
                    <TranslatedMessage
                      {...messages.outreachComposeModalWillBeSentTo}
                      values={{
                        a: chunks => <strong>{chunks}</strong>,
                        SCHEDULE: getFormattedDateWithTimestamp(schedule),
                      }}
                    />
                  </p>
                </Media.Body>
                <Media.Item>
                  <TkUiButton
                    data-qa="3hDxjYzPoLSMoqc9xHaQu"
                    modifiers={['inline-flex']}
                    onClick={this.onRemoveSchedule}
                  >
                    <Addon>
                      <SvgIcon
                        icon="timerOff"
                        width={20}
                        height={20}
                        modifiers={['block', 'right-small']}
                      />
                    </Addon>
                    <TranslatedMessage
                      {...messages.outreachComposeModalRemove}
                    />
                  </TkUiButton>
                </Media.Item>
              </Media>
            </StatusBar>
          )}
          <Form
            model={formModel}
            onSubmit={this.onSubmit}
            onChange={
              this.props.isSaving ? () => {} : this.throttledBackgroundSave
            }
            validators={validators}
          >
            <EmailForm.EmailHeaderGroup
              clearBccs={this.clearBccs}
              clearCcs={this.clearCcs}
              optionsFrom={optionsFrom}
              isSubjectFieldValid={isSubjectFieldValid}
              isSubjectFieldTouched={isSubjectFieldTouched}
            />
            <EmailForm.EmailContentGroup
              modifiers={['shadowed']}
              placeholder={emailBodyPlaceholder}
              className="compose-modal-email-content-group"
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Action justify="left">
            <Button
              data-qa="XiCDwyN2MgWx_d6qsZRkA"
              level="tertiary"
              size="lg"
              onClick={this.onClickDiscard}
              style={{ marginRight: '10px' }}
            >
              <TranslatedMessage {...messages.outreachComposeModalDiscard} />
            </Button>
            {showSaveDraft && (
              <Button
                data-qa="ozm3ivx-XDPGUobkz81OT"
                level="tertiary"
                size="lg"
                onClick={this.onClickSaveDraft}
              >
                {schedule ? 'Close' : 'Save Draft'}
              </Button>
            )}
          </Modal.Action>
          <Modal.Action>
            <Button
              data-qa="EG1SJ0Q_mqUxv2GBLWc52"
              disabled={disableSendButton}
              level="link"
              size="sm"
              onClick={this.onClickSendTest}
              style={{
                paddingLeft: '0',
                paddingRight: '0',
              }}
            >
              <TranslatedMessage {...messages.outreachComposeModalSendTest} />
            </Button>
          </Modal.Action>
          {showScheduleModal && (
            <Modal.Action>
              <TkUiButton
                data-qa="a9n9RyrW5onIcqyBjq5i2"
                modifiers={['inline-flex', 'secondary', 'round']}
                onClick={this.onOpenScheduleModal}
              >
                <div style={{ paddingRight: '5px', display: 'flex' }}>
                  <SvgIcon icon="timer" width={20} height={20} />
                </div>
                <span>
                  <TranslatedMessage
                    {...messages.outreachComposeModalSchedule}
                    values={{
                      EDIT: schedule && 'Edit',
                    }}
                  />
                </span>
              </TkUiButton>
            </Modal.Action>
          )}
          <Modal.Action>
            <Button
              data-qa="I1L_cqAPkZQVB96rPOVwC"
              disabled={disableSendButton}
              level="primary"
              size="lg"
              onClick={this.onClickSave}
            >
              <TranslatedMessage {...messages.outreachComposeModalSend} />
            </Button>
          </Modal.Action>
        </Modal.Footer>
        <ScheduleModal
          isOpen={isScheduleModalOpen}
          onCancel={this.onCancelScheduleModal}
          onConfirm={this.onConfirmScheduleModal}
          onRemove={this.onRemoveScheduleModal}
          selectionsAreValid={this.scheduleModalSelectionsAreValid}
          header="Schedule Messages"
          confirmButtonText="Schedule"
          showRemoveSchedule={!!composeForm.schedule}
          date={
            composeForm.schedule ? composeForm.schedule.timestamp : undefined
          }
          time={time}
          timeLabel={time}
          selectedTimezoneName={
            composeForm.schedule ? composeForm.schedule.timezone : undefined
          }
        />
        <Modal isOpen={isScheduleModalErrorOpen} size="sm">
          <Modal.Header status="danger">
            <TranslatedMessage
              {...messages.outreachComposeModalScheduleFailedScheduleTitle}
            />
          </Modal.Header>
          <Modal.Body>
            <TranslatedMessage
              {...messages.outreachComposeModalScheduleFailedScheduleBody}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              data-qa="jyI0-AMTuIKLH9NYn6THu"
              level="primary"
              size="lg"
              onClick={this.handleErrorConfirm}
            >
              <TranslatedMessage {...globalMessages.ok} />
            </Button>
          </Modal.Footer>
        </Modal>
        <ConfirmModal
          isOpen={isOpenAreYouSureModal}
          onCancel={this.onCancelAreYouSureModal}
          onConfirm={this.onConfirmAreYouSureModal}
          header={
            isUnmodifiedReply
              ? 'Send reply without modifications?'
              : 'Send without a subject or body?'
          }
          confirmButtonText="Yes, Send"
        />
        <OutreachDiscardModal
          isOpen={isOpenDiscardModal}
          onCancel={this.onCancelDiscardModal}
          onConfirm={this.onConfirmDiscardModal}
          toggle={this.toggleDiscardModal}
        />
        <OutreachSendingModal
          error={error}
          isOpen={isOpenSendingModal}
          isSending={isSaving}
          isTest={isSendingTest}
          onCancel={this.onCancelSendingModal}
          subject={composeForm.subject}
          from={composeForm.from}
          to={composeForm.to}
        />
      </Modal>
    );
  }
}

OutreachComposeModal.propTypes = {
  clearMessageSaveError: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  closeScheduleModal: PropTypes.func.isRequired,
  closeScheduleModalError: PropTypes.func.isRequired,
  defaultIntegration: PropTypes.object,
  verifiedEmails: PropTypes.object.isRequired,
  deleteDraft: PropTypes.func.isRequired,
  error: PropTypes.object,
  hasDevFeatureFlag: PropTypes.func.isRequired,
  hasJortsDrafts: PropTypes.bool,
  history: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isPristine: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  isScheduleModalErrorOpen: PropTypes.bool.isRequired,
  isScheduleModalOpen: PropTypes.bool.isRequired,
  isSubjectFieldValid: PropTypes.bool.isRequired,
  isSubjectFieldTouched: PropTypes.bool.isRequired,
  isUnmodifiedReply: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  composeForm: PropTypes.object.isRequired,
  openComposeModalWithValues: PropTypes.func.isRequired,
  ownedIntegrations: PropTypes.arrayOf(PropTypes.object).isRequired,
  removeDraftSchedule: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  saveDraft: PropTypes.func.isRequired,
  saveDraftAndCloseModal: PropTypes.func.isRequired,
  saveDraftSchedule: PropTypes.func.isRequired,
  send: PropTypes.func.isRequired,
  sendTest: PropTypes.func.isRequired,
  setForm: PropTypes.func.isRequired,
  setValidity: PropTypes.func.isRequired,
  showSaveDraft: PropTypes.bool.isRequired,
  submitForm: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  toggleScheduleModal: PropTypes.func.isRequired,
  isValidForSendgrid: PropTypes.bool.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
};

OutreachComposeModal.defaultProps = {
  defaultIntegration: null,
  verifiedEmails: {},
  error: null,
  hasJortsDrafts: false,
  showSaveDraft: false,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
};

export default injectIntl(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(OutreachComposeModal),
  ),
);
