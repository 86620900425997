import { createSelector } from 'reselect';

import {
  DEV_FEATURES,
  EMAIL_ANNOUNCEMENT_CONTENT_SIZE_LIMIT,
} from 'constants/constants';
import { hasMockOutreachDataSelector } from 'reducers/outreach/mockData';
import { hasDevFeatureFlagSelector } from 'selectors/account';

import { mockedRecipientItems, mockedRecipientsTotal } from './Email-mock';
import {
  EmailGetDraftError,
  EmailState,
  EmailUpdateDraftError,
} from './Email-types';

type EmailSelectorArgs = {
  email: EmailState;
};

export const emailCampaignLinkTracking = ({ email }: EmailSelectorArgs) =>
  email?.linkTrackingEnabled;
export const emailAttachmentsSelector = ({ email }: EmailSelectorArgs) =>
  email.attachments;
export const emailDefaultOptOutAddressSelector = ({
  email,
}: EmailSelectorArgs) => email.defaultOptOutAddress;
export const emailAdditionalRecipientsSelector = ({
  email,
}: EmailSelectorArgs) => email.document.additionalRecipients;
export const emailBodySelector = ({ email }: EmailSelectorArgs) =>
  email.document.body;
export const emailFromSelector = ({ email }: EmailSelectorArgs) =>
  email.document.from;
export const emailIdSelector = ({ email }: EmailSelectorArgs) =>
  email.document.id;
export const emailIsCopySelector = ({ email }: EmailSelectorArgs) =>
  email.document.isCopy;
export const emailOptOutSettingsSelector = ({ email }: EmailSelectorArgs) =>
  email.document.optOutSettings;
export const emailSenderNameSelector = ({ email }: EmailSelectorArgs) =>
  email.document.senderName;
export const emailReplyToSelector = ({ email }: EmailSelectorArgs) =>
  email.document.replyTo;
export const emailSendToSelfSelector = ({ email }: EmailSelectorArgs) =>
  email.document.sendToSelf;
export const emailSubjectSelector = ({ email }: EmailSelectorArgs) =>
  email.document.subject;
export const emailEntryLocationSelector = ({ email }: EmailSelectorArgs) =>
  email.entryLocation;
export const emailSettingsFullOptOutAdresssToggle = ({
  email,
}: EmailSelectorArgs) => email.emailSettingsFullOptOutAddressToggle;
export const emailAddRecipientsErrorSelector = ({ email }: EmailSelectorArgs) =>
  email.error.addRecipients;
export const emailCreateDraftErrorSelector = ({ email }: EmailSelectorArgs) =>
  email.error.createEmailDraft;
export const emailDeleteDraftErrorSelector = ({ email }: EmailSelectorArgs) =>
  email.error.deleteEmailDraft;
export const emailDeleteRecipientsErrorSelector = ({
  email,
}: EmailSelectorArgs) => email.error.deleteRecipients;
export const emailGetDraftErrorSelector = ({ email }: EmailSelectorArgs) =>
  email.error.getEmailDraft;
export const emailGetOptOutAddressErrorSelector = ({
  email,
}: EmailSelectorArgs) => email.error.getOptOutAddress;
export const emailGetRecipientsErrorSelector = ({ email }: EmailSelectorArgs) =>
  email.error.getRecipients;
export const emailSendEmailErrorSelector = ({ email }: EmailSelectorArgs) =>
  email.error.sendEmail;
export const emailSendEmailPreviewErrorSelector = ({
  email,
}: EmailSelectorArgs) => email.error.sendEmailPreview;
export const emailUpdateEmailErrorSelector = ({ email }: EmailSelectorArgs) =>
  email.error.updateEmailDraft;
export const emailUnscheduleEmailErrorSelector = ({
  email,
}: EmailSelectorArgs) => email.error.unscheduleEmail;
export const emailFieldsSelector = ({ email }: EmailSelectorArgs) =>
  email.field;
export const emailImagesSelector = ({ email }: EmailSelectorArgs) =>
  email.images;
export const emailIsLoadingSelector = ({ email }: EmailSelectorArgs) =>
  email.loading;
export const emailAddRecipientsLoadingSelector = ({
  email,
}: EmailSelectorArgs) => email.loading.addRecipients;
export const emailCreateDraftLoadingSelector = ({ email }: EmailSelectorArgs) =>
  email.loading.createEmailDraft;
export const emailDeleteAttachmentLoadingSelector = ({
  email,
}: EmailSelectorArgs) => email.loading.deleteAttachment;
export const emailDeleteDraftLoadingSelector = ({ email }: EmailSelectorArgs) =>
  email.loading.deleteEmailDraft;
export const emailDeleteImagesLoadingSelector = ({
  email,
}: EmailSelectorArgs) => email.loading.deleteImages;
export const emailDeleteImagesProgressSelector = ({
  email,
}: EmailSelectorArgs) => email.deletingImagesProgress;
export const emailDeleteRecipientsLoadingSelector = ({
  email,
}: EmailSelectorArgs) => email.loading.deleteRecipients;
export const emailGetDraftLoadingSelector = ({ email }: EmailSelectorArgs) =>
  email.loading.getEmailDraft;
export const emailGetOptOutAddressLoadingSelector = ({
  email,
}: EmailSelectorArgs) => email.loading.getOptOutAddress;
export const emailGetRecipientsLoadingSelector = ({
  email,
}: EmailSelectorArgs) => email.loading.getRecipients;
export const emailSendEmailLoadingSelector = ({ email }: EmailSelectorArgs) =>
  email.loading.sendEmail;
export const emailSendEmailPreviewLoadingSelector = ({
  email,
}: EmailSelectorArgs) => email.loading.sendEmailPreview;
export const emailSetOptOutAddressLoadingSelector = ({
  email,
}: EmailSelectorArgs) => email.loading.setOptOutAddress;
export const emailUpdateEmailDraftLoadingSelector = ({
  email,
}: EmailSelectorArgs) => email.loading.updateEmailDraft;
export const emailGetVirtualSelectionRecipientsCountLoadingSelector = ({
  email,
}: EmailSelectorArgs) => email.loading.getVirtualSelectionRecipientsCount;
export const emailOptOutAddressEditingSelector = ({
  email,
}: EmailSelectorArgs) => email.optOutAddressEditing;
export const emailVerifiedEmailsLoadingSelector = ({
  email,
}): EmailSelectorArgs => email.loading.getVerifiedEmails;
export const emailVerifiedEmailsSelector = ({ email }): EmailSelectorArgs =>
  email.verifiedEmails;
export const emailRecipientItemsSelector = createSelector(
  ({ email }: EmailSelectorArgs) => email.recipientItems,
  hasMockOutreachDataSelector,
  (items, hasMockOutreachData) =>
    hasMockOutreachData ? mockedRecipientItems : items,
);
export const emailVirtualSelectionRecipientsCountSelector = ({
  email,
}: EmailSelectorArgs) => email.virtualSelectionRecipientsCount;
export const emailRecipientItemsTotalSelector = createSelector(
  ({ email }: EmailSelectorArgs) => email.recipientItemsTotal,
  hasMockOutreachDataSelector,
  (itemsTotal, hasMockOutreachData) =>
    hasMockOutreachData ? mockedRecipientItems.length : itemsTotal,
);
export const emailRecipientsTotalSelector = createSelector(
  ({ email }: EmailSelectorArgs) => email.recipientsTotal,
  hasMockOutreachDataSelector,
  (recipientsTotal, hasMockOutreachData) =>
    hasMockOutreachData ? mockedRecipientsTotal : recipientsTotal,
);
export const emailUpdatePendingSelector = ({ email }: EmailSelectorArgs) =>
  email?.updatePending;

export const emailImportFromWordCancelledSelector = ({
  email,
}: EmailSelectorArgs) => email.importFromWord.cancelled;
export const emailScheduledSelector = ({ email }: EmailSelectorArgs) =>
  email.scheduling.scheduled;
export const emailScheduledTimestampSelector = ({ email }: EmailSelectorArgs) =>
  email.scheduling.timestamp;
export const emailScheduledUtcOffsetSelector = ({ email }: EmailSelectorArgs) =>
  email.scheduling.utcOffset;
export const emailScheduledUtcTimezoneSelector = ({
  email,
}: EmailSelectorArgs) => email.scheduling.utcTimezone;
export const emailScheduledInvalidDateSelector = ({
  email,
}: EmailSelectorArgs) => email.scheduling.invalidDate;
export const emailScheduledMinTimeSelector = ({ email }: EmailSelectorArgs) =>
  email.scheduling.minTime;
export const emailTitleSelector = ({ email }: EmailSelectorArgs) =>
  email.document?.title;
export const emailCampaignsSelector = state => {
  const shouldShowAllCampaignAssociations = hasDevFeatureFlagSelector(state)(
    DEV_FEATURES.showAllAccountCampaignAssociations,
  );
  const { email }: EmailSelectorArgs = state;
  if (shouldShowAllCampaignAssociations) {
    return email.campaigns;
  } else {
    return email.campaigns?.filter(c => c.isMine || c.shared);
  }
};
export const emailSenderAuthorizationStatusSelector = ({
  email,
}: EmailSelectorArgs) => email.senderAuthorizationStatus.authorized;
export const emailVerificationSelector = ({ email }) => email.emailVerification;

export const emailIsUpToDateSelector = createSelector(
  emailAddRecipientsLoadingSelector,
  emailDeleteRecipientsLoadingSelector,
  emailGetDraftLoadingSelector,
  emailUpdateEmailDraftLoadingSelector,
  emailUpdatePendingSelector,
  emailGetDraftErrorSelector,
  emailUpdateEmailErrorSelector,
  emailDeleteImagesLoadingSelector,
  (
    addRecipientLoading,
    deleteRecipientLoading,
    getDraftLoading,
    updateDraftLoading,
    updateDraftPending,
    getDraftError,
    updateDraftError,
    deleteImagesLoading,
  ) => {
    return (
      !addRecipientLoading &&
      !deleteRecipientLoading &&
      !getDraftLoading &&
      !updateDraftLoading &&
      !updateDraftPending &&
      getDraftError.type === EmailGetDraftError.None &&
      updateDraftError.type === EmailUpdateDraftError.None &&
      !deleteImagesLoading
    );
  },
);

// prettier-ignore-end
export const emailCampaignIdsSelector = createSelector(
  emailCampaignsSelector,
  campaigns => campaigns.map(c => c.id),
);

export const emailFieldsAreValidSelector = (includeRecipients: boolean) => ({
  email,
}: EmailSelectorArgs) =>
  !Object.keys(email.field).some(key => {
    if (key === 'recipients' && !includeRecipients) {
      return false;
    } else {
      return !email.field[key].isValid;
    }
  });

export const emailFieldsInvalidSelector = ({ email }: EmailSelectorArgs) => {
  return Object.values(email.field).every(section => !section.isValid);
};

export const emailImagesUploadingSelector = ({ email }: EmailSelectorArgs) => {
  return email.images.some(image => {
    return image.loading;
  });
};

export const emailCurrentFileDataSelector = ({ email }: EmailSelectorArgs) => {
  return email?.currentFileData;
};

export const emailAttachmentsUploadingSelector = ({
  email,
}: EmailSelectorArgs) => {
  return email.attachments.some(attachment => {
    return attachment.loading;
  });
};

export const emailAttachmentsTotalSizeSelector = ({
  email,
}: EmailSelectorArgs) => {
  return email?.attachmentsTotalSize;
};

export const emailEditorContentSizeSelector = ({ email }: EmailSelectorArgs) =>
  email?.editorContentSize;

export const emailTotalSizeSelector = createSelector(
  emailAttachmentsTotalSizeSelector,
  emailEditorContentSizeSelector,
  (attachmentTotalSize, editorContentSize) =>
    attachmentTotalSize + editorContentSize,
);

export const emailContentSizeLimitExceededSelector = createSelector(
  emailTotalSizeSelector,
  emailTotalSize => {
    return (
      emailTotalSize / Math.pow(1024, 2) > EMAIL_ANNOUNCEMENT_CONTENT_SIZE_LIMIT
    );
  },
);
